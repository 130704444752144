import React, { FC } from 'react';
import * as translations from '../../strings';
import { getLangKey } from '../../../../../../../../../models/Language';
import styles from '../styles.module.css';

const strings = translations[getLangKey()];

const MAX_SHOWN_USERS = 10;

type Props = {
  reactionUserIds: number[];
  currentUserId: number;
  users: { id: number; name: string }[];
};

export const Reacted: FC<Props> = ({
  reactionUserIds,
  currentUserId,
  users
}) => {
  const getReactionUser = (reactionUserId: number) =>
    users.find(({ id }) => id === reactionUserId);

  const reactionsUsers = reactionUserIds
    .map(reactionUserId => {
      return getReactionUser(reactionUserId);
    })
    .filter(reactionUser => reactionUser !== undefined);

  const currentUserIndex = reactionsUsers.findIndex(
    ({ id }) => id === currentUserId
  );
  if (currentUserIndex) {
    reactionsUsers.unshift(reactionsUsers.splice(currentUserIndex, 1)[0]);
  }

  const shownUsers = reactionsUsers.slice(0, MAX_SHOWN_USERS);
  const totalShownUsers = shownUsers.length;
  const lastIndex = totalShownUsers - 1;
  const secondLastIndex = totalShownUsers - 2;
  const hiddenUsers = reactionsUsers.slice(MAX_SHOWN_USERS);
  const totalHiddenUsers = hiddenUsers.length;

  const joinSeparator = (index: number) => {
    let separator = ', ';
    if (index === lastIndex) separator = '';
    if (index === secondLastIndex && !totalHiddenUsers) separator = ' and ';
    return separator;
  };

  return (
    <>
      <span key="shown">
        {shownUsers.map(({ name, id }, index) => (
          <span key={name} className={styles.reactedUser}>
            {id === currentUserId ? strings.you : name}
            {joinSeparator(index)}
          </span>
        ))}
      </span>
      {!!totalHiddenUsers && (
        <span
          key="end"
          title={hiddenUsers.map(({ name }) => name).join('\n')}
          className={styles.others}
        >
          {strings.plusOthers(totalHiddenUsers)}
        </span>
      )}
    </>
  );
};
