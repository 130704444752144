import React, {
  FC,
  useRef,
  useState,
  MutableRefObject,
  useEffect
} from 'react';

import Screenshot, {
  Props as ScreenshotProps
} from '../../../clients/sidebar/components/screenshots';
import TaskInfo from './task_info';
import DetailsHead from './details_head';
import { Props as DetailsHeadProps } from './details_head/types';
import { Props as TaskInfoProps } from './task_info/types';
import {
  EventList as EventListProps,
  Comments as CommentsProps
} from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/CommentsAndLog/types';
import CommentsAndLog from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/CommentsAndLog';
import camelCaseKeys from 'camelcase-keys';
import cx from 'classnames';
import TitleBar from './details_head/title_bar';
import styles from './index.module.css';
import useMediaQuery from '../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';
import useComments from '../../hooks/useComments';
import useScreenshotView from '../../hooks/useScreenshotView';
import getParagonIntegrations from '../../utils/getParagonIntegrations';
import DetailsTab from '../../../clients/sidebar/components/DetailsTab';
import useParagonIntegrations from 'appJS/hooks/useParagonIntegrations';
import Integrations from '../../components/Integrations';
import { WebIntegration } from 'appJS/components/Integrations/types';
import IntegrationLink from '../../../clients/sidebar/views/SidebarPanel/Detailbar/IntegrationLink/IntegrationLink';
import * as translations from './strings';
import { getLangKey } from '../../models/Language';
import { getScreenshotServerUrl } from '../../models/Application';
import { AntdWrapper } from '../../../clients/shared/AntdWrapper';
import { ProjectProvider } from '../../../clients/providers/Project';
import { CurrentUserProvider } from '../../../clients/providers/CurrentUser';
import { Modal } from 'antd';
import TaskActions from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/TaskActions';
import ReportedBy from '../../../clients/sidebar/views/SidebarPanel/Detailbar/ReportedBy';
import usePusherSubscribe from 'appJS/hooks/usePusherSubscribe';
import { Event, Subscription } from 'appJS/models/Pusher';

const strings = translations[getLangKey()];

interface Props {
  detailsHead: DetailsHeadProps;
  taskInfo: TaskInfoProps;
  screenshot: ScreenshotProps;
  eventList: EventListProps;
  comments: CommentsProps;
  integrations: any;
  open: boolean;
  closeModal: () => void;
  openModal: () => void;
}

const TaskDetails: FC<Props> = props => {
  const {
    detailsHead,
    taskInfo,
    screenshot,
    eventList,
    open,
    closeModal,
    openModal
  } = props;
  const outerRef: MutableRefObject<null | HTMLDivElement> = useRef(null);
  const videoRef: MutableRefObject<null | HTMLVideoElement> = useRef(null);
  const useParentWindow: boolean = true;
  const isSmallView = useMediaQuery(
    '(max-width: 1360px) and (min-width: 950px)'
  );
  const isMobileView = useMediaQuery('(max-width: 475px)', useParentWindow);
  const [isTaskInfo, setIsTaskInfo] = useState<boolean>(true);

  const {
    column,
    localTaskId,
    projectId,
    projectName,
    canViewTaskBoard,
    isAdminView,
    creator,
    createdAt,
    canMoveToProject,
    canDeleteTask,
    editStatus,
    destinations,
    secretId,
    markAsStatus,
    projects,
    moveToProject,
    deleteTask,
    pusherApiKey,
    pusherChannelName
  } = detailsHead;

  const [taskEditStatus, setTaskEditStatus] = useState<boolean>(editStatus);

  const reportedProps =
    typeof creator === 'object' && creator !== null
      ? creator
      : { display: creator };

  const {
    container,
    commentsLoading,
    currentUser,
    users,
    guestVisible,
    taskId,
    canEditComment,
    canViewPrivateComments,
    canCreatePrivateComments,
    canViewCommitComment,
    billingRights,
    owner,
    orgId,
    bugherdUrl
  } = props.comments;

  const {
    connectedIntegrations,
    harvestIntegration,
    paragonEnabled,
    fullstorySessionUrl,
    logrocketSessionUrl
  } = props.integrations;
  const paragonIntegration = getParagonIntegrations(
    props.integrations.paragonIntegration
  );

  const {
    screenshotData: { url }
  } = screenshot;

  const toggleExpandedView = () => {
    if (!open) {
      openModal();
    } else {
      closeModal();
    }
  };

  const closeDetailBar = () => {
    closeModal();
    detailsHead.closeDetailBar();
    videoRef?.current?.pause();
  };

  const canViewTaskBoardLink = canViewTaskBoard && !isAdminView;

  const isRecording: boolean =
    !!url && (url.endsWith('.webm') || url.endsWith('.mp4'));

  const hasSelector = !(
    typeof taskInfo.selector !== 'object' || !taskInfo.selector.path
  );

  const { createComment, deleteComment, updateComment, comments } = useComments(
    {
      taskId,
      projectId,
      bugherdUrl,
      comments: props.comments.comments,
      currentUser
    }
  );

  const {
    isCroppedView,
    handleScreenshotToggle,
    handleRegenerateScreenshot
  } = useScreenshotView({
    userId: currentUser.id,
    projectId,
    organizationId: orgId,
    hasSelector,
    basicAuthUrl: screenshot.basicAuthUrl,
    onRegenerateScreenshot: (key: string) => {
      taskInfo.setProperty({
        retry_screenshot_at: new Date().toISOString(),
        screenshot_key: key
      });
    }
  });

  const {
    paragonIntegrations,
    paragonIntegrationsData,
    loadingIntegrationsData,
    loadingIntegrations,
    integrationsError,
    getIntegrationsInformation
  } = useParagonIntegrations({
    projectId,
    paragonEnabled,
    apiDomain: bugherdUrl,
    isTaskInfo
  });

  const webIntegrations: WebIntegration[] = [];

  if (fullstorySessionUrl) {
    webIntegrations.push({
      key: 'fullStory',
      integration: {
        id: 'View session',
        url: fullstorySessionUrl
      }
    });
  }

  if (logrocketSessionUrl) {
    webIntegrations.push({
      key: 'logRocket',
      integration: {
        id: 'View session',
        url: logrocketSessionUrl
      }
    });
  }

  const handleGuestEditTaskStatusUpdate = (
    isGuestUser: boolean,
    pusherData: any
  ) => {
    if (isGuestUser) {
      setTaskEditStatus(pusherData.guest_edit_task_status);
    }
  };

  usePusherSubscribe({
    pusher: {
      config: {
        pusherApiKey,
        pusherChannelAuthEndpoint: '/pusher/auth'
      },
      projects: [
        {
          id: String(projectId),
          pusherChannelName
        }
      ],
      events: [
        {
          name: Event.GUEST_EDIT_STATUS_UPDATED,
          onUpdate: handleGuestEditTaskStatusUpdate.bind(
            null,
            currentUser.role === 'guest'
          )
        }
      ],
      subscription: Subscription.TASK_ACTIONS
    },
    loading: false
  });

  const harvestEnabled = !!harvestIntegration?.id;

  const showDetailsTab = !!(
    currentUser.role !== 'guest' &&
    (paragonEnabled ||
      connectedIntegrations.length ||
      webIntegrations.length ||
      harvestEnabled)
  );

  const harvestEmbedUrl = `https://platform.harvestapp.com/platform/timer?app_name=BugHerd&closable=false&chromeless=true&permalink=${bugherdUrl}/projects/${projectId}/tasks/${localTaskId}&external_item_id=${localTaskId}&external_group_id=${projectId}&external_group_name=${projectName}`;

  const TaskActionsComponent = () => (
    <TaskActions
      taskMoveToProject={canMoveToProject}
      taskDelete={canDeleteTask}
      taskEditStatus={taskEditStatus}
      container={container}
      // @ts-ignore
      columns={destinations}
      // @ts-ignore
      column={column}
      taskId={taskId}
      projectId={projectId}
      shareUrl={`${bugherdUrl}/t/${secretId}`}
      updateTask={markAsStatus}
      isAdminView={isAdminView}
      isSmallDetailsView={false}
      // @ts-ignore
      projects={projects}
      // @ts-ignore
      moveToProject={moveToProject}
      // @ts-ignore
      deleteTask={deleteTask}
    />
  );

  const TaskDetailsHeadComponent = () => (
    <DetailsHead
      {...Object.assign(detailsHead, {
        className: styles.detailsHead,
        key: taskId,
        showTaskActions: !open,
        taskActions: TaskActionsComponent
      })}
    />
  );

  const CommentsComponent = () => (
    <div className={styles.eventLogAndComments}>
      <CommentsAndLog
        container={document.body}
        commentsLoading={!!commentsLoading}
        comments={comments}
        currentUserId={currentUser.id}
        // @ts-ignore
        users={camelCaseKeys(
          users.filter(user => !!user.is_on_project),
          { deep: true }
        )}
        // @ts-ignore
        usersNotOnProject={camelCaseKeys(
          users.filter(user => !user.is_on_project),
          { deep: true }
        )}
        // @ts-ignore
        logs={camelCaseKeys(eventList.eventList, { deep: true })}
        taskId={taskId}
        canEditComment={canEditComment}
        canViewPrivateComments={canViewPrivateComments}
        canCreatePrivateComments={canCreatePrivateComments}
        canViewCommitComment={canViewCommitComment}
        billingRights={billingRights}
        // @ts-ignore
        owner={owner}
        bugherdUrl={bugherdUrl}
        organizationId={orgId}
        guestVisible={!!guestVisible}
        createComment={createComment}
        deleteComment={deleteComment}
        updateComment={updateComment}
        isSmallView={isSmallView}
        isAdminView={isAdminView}
        projectId={projectId}
        isExpandedView={open}
        apiDomain={bugherdUrl}
      />
    </div>
  );

  const TaskDetailsTab = () => (
    <DetailsTab
      isTaskInfo={isTaskInfo}
      handleOnChange={setIsTaskInfo}
      isLoadingParagonIntegrations={
        loadingIntegrations || loadingIntegrationsData
      }
      integrationsError={integrationsError}
      container={container}
      getIntegrationsInformation={getIntegrationsInformation}
    />
  );

  const Details = () => (
    <div
      className={styles.detailsMiddle}
      onScroll={event => event.stopPropagation()}
    >
      <div className={styles.screenshotWrapper}>
        <Screenshot
          {...Object.assign(screenshot, { videoRef })}
          isCroppedView={isCroppedView}
          handleScreenshotToggle={handleScreenshotToggle}
          handleRegenerateScreenshot={handleRegenerateScreenshot}
          apiDomain={bugherdUrl}
          screenshotServerUrl={getScreenshotServerUrl()}
        />
      </div>
      {open && (
        <>
          <ReportedBy
            {...{
              createdAt,
              ...reportedProps
            }}
          />
          {TaskActionsComponent()}
        </>
      )}
      {fullstorySessionUrl && (
        <IntegrationLink
          title={strings.fullStoryTitle}
          url={fullstorySessionUrl}
          linkText={strings.viewSession}
          titleIcon={
            <span
              className={cx(styles.integrationLogo, styles.fullStoryLogo)}
            />
          }
        />
      )}
      {logrocketSessionUrl && (
        <IntegrationLink
          title={strings.logRocketTitle}
          url={logrocketSessionUrl}
          linkText={strings.viewSession}
          titleIcon={
            <span
              className={cx(styles.integrationLogo, styles.logRocketLogo)}
            />
          }
        />
      )}
      <TaskInfo {...Object.assign(taskInfo, { isRecording })} />
    </div>
  );

  const IntegrationsComponent = () => (
    <div className={styles.integrationsWrapper}>
      <Integrations
        connectedIntegrations={camelCaseKeys(connectedIntegrations, {
          deep: true
        })}
        harvestIntegration={harvestIntegration}
        harvestEmbedUrl={harvestEmbedUrl}
        taskConnections={paragonIntegration}
        container={container}
        projectId={projectId}
        bugherdUrl={bugherdUrl}
        isAdminView={isAdminView}
        taskId={taskId}
        isSmallView={isSmallView}
        paragonIntegrationsData={paragonIntegrationsData}
        paragonIntegrations={paragonIntegrations}
        loading={loadingIntegrationsData || loadingIntegrations}
        webIntegrations={webIntegrations}
      />
    </div>
  );

  return (
    <div className={cx(styles.taskDetailsWrapper, { mobile: isMobileView })}>
      <TitleBar
        key={`${taskId}-titlebar`}
        closeDetailBar={closeDetailBar}
        toggleExpandedView={toggleExpandedView}
        column={column}
        localTaskId={localTaskId}
        projectId={projectId}
        url={detailsHead.url}
        priorityId={taskInfo.priorityId}
        isExpandedView={open}
        canViewTaskBoardLink={canViewTaskBoardLink}
      />
      {open ? (
        <div className={styles.modalContent}>
          <div className={styles.leftPane}>
            {TaskDetailsHeadComponent()}
            {CommentsComponent()}
          </div>
          <div className={styles.rightPane}>
            {showDetailsTab && <TaskDetailsTab />}
            {isTaskInfo || !showDetailsTab ? (
              <Details />
            ) : (
              <IntegrationsComponent />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.container} ref={outerRef}>
          {TaskDetailsHeadComponent()}

          {showDetailsTab && (
            <DetailsTab
              isTaskInfo={isTaskInfo}
              handleOnChange={setIsTaskInfo}
              isLoadingParagonIntegrations={
                loadingIntegrations || loadingIntegrationsData
              }
              integrationsError={integrationsError}
              container={container}
              getIntegrationsInformation={getIntegrationsInformation}
            />
          )}

          {isTaskInfo || !showDetailsTab ? (
            <>
              <Details />
              {CommentsComponent()}
            </>
          ) : (
            <IntegrationsComponent />
          )}
        </div>
      )}
    </div>
  );
};

export default (props: Props) => {
  const [open, setModal] = useState<boolean>(false);
  const paneRightRef = useRef<HTMLDivElement>(null);
  const paneCenterRef = useRef<HTMLDivElement>(null);
  const isDesktopView = useMediaQuery('(min-width: 980px)');

  useEffect(() => {
    paneRightRef.current = document.querySelector(
      '.pane-right'
    ) as HTMLDivElement;
    paneCenterRef.current = document.querySelector(
      '.pane-center'
    ) as HTMLDivElement;
    document.body.classList.add('antd-container');
  }, []);

  const closeModal = () => {
    setModal(false);
    paneRightRef.current?.classList.remove(styles.paneRightExpanded);
    paneCenterRef.current?.classList.remove(styles.paneCenterExpanded);
  };

  const openModal = () => {
    setModal(true);
    paneRightRef.current?.classList.add(styles.paneRightExpanded);
    paneCenterRef.current?.classList.add(styles.paneCenterExpanded);
  };

  const { bugherdUrl } = props.comments;
  const { projectId } = props.detailsHead;

  return (
    <AntdWrapper>
      <ProjectProvider apiDomain={bugherdUrl} id={`${projectId}`}>
        <CurrentUserProvider apiDomain={bugherdUrl}>
          {open ? (
            isDesktopView && (
              <Modal
                open
                closable={false}
                getContainer={props.detailsHead.container}
                onCancel={closeModal}
                title={null}
                footer={null}
                centered
                className={styles.modal}
              >
                <TaskDetails
                  {...props}
                  open={true}
                  closeModal={closeModal}
                  openModal={openModal}
                />
              </Modal>
            )
          ) : (
            <TaskDetails
              {...props}
              open={open}
              openModal={openModal}
              closeModal={closeModal}
            />
          )}
        </CurrentUserProvider>
      </ProjectProvider>
    </AntdWrapper>
  );
};
