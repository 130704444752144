import React, { FC, useState, useEffect } from 'react';
import cx from 'classnames';
import styles from './styles.module.css';
import * as translations from '../strings';
import { getLangKey } from 'appJS/models/Language';
import { getNewReactions, Emoji, UserReaction } from './getNewReactions';
import { PickerButton } from './PickerButton';
import { CommentReactions } from './CommentReactions';
import { UpdateComment } from '../../../../../../../../../shared/updateComment';

const strings = translations[getLangKey()];

type Props = {
  reactions: string;
  id: number;
  isPrivate: boolean;
  currentUser: { id: number };
  users: { id: number; name: string }[];
  updateComment: UpdateComment;
  getContainer: () => HTMLDivElement;
  taskId: number;
  apiDomain: string;
};

const Reactions: FC<Props> = ({
  currentUser,
  getContainer,
  users,
  updateComment,
  reactions,
  id: commentId,
  isPrivate,
  taskId,
  apiDomain
}) => {
  const [commentReactions, setCommentReactions] = useState<{
    [key: string]: { name: string; userReactions: UserReaction[] };
  }>(JSON.parse(reactions || '{}'));
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const updateReactions = ({
    reactionKey,
    emoji
  }: {
    reactionKey?: string;
    emoji?: Emoji;
  }) => {
    const newReactions = getNewReactions({
      commentReactions,
      reactionKey,
      emoji,
      currentUserId: currentUser.id
    });
    updateComment({
      commentId,
      commentEvent: {
        type: 'reaction',
        reactions: JSON.stringify(newReactions)
      },
      taskId
    });
    setCommentReactions(newReactions);
  };

  const handleEmojiSelect = (emoji: Emoji) => {
    updateReactions({ emoji });
    setPopoverOpen(false);
  };

  useEffect(() => {
    if (reactions !== JSON.stringify(commentReactions)) {
      setCommentReactions(JSON.parse(reactions || '{}'));
    }
  }, [reactions]);

  return (
    <div
      className={cx(styles.container, {
        [styles.isPrivate]: isPrivate,
        privateComment: isPrivate
      })}
    >
      <CommentReactions
        currentUser={currentUser}
        getContainer={getContainer}
        users={users}
        updateReactions={updateReactions}
        commentReactions={commentReactions}
      />
      <PickerButton
        getContainer={getContainer}
        onEmojiSelect={handleEmojiSelect}
        tooltipTitle={strings.addReaction}
        placement="leftBottom"
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        reactionPicker
        apiDomain={apiDomain}
      />
    </div>
  );
};

export default Reactions;
