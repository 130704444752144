import React, { FC, useState } from 'react';
import cx from 'classnames';
import { Store } from 'emoji-mart';
import Picker from '@emoji-mart/react';
import { skinTones, SkinTone } from '../skinTones';
import styles from './styles.module.css';
import { categoryIcons } from './categoryIcons';
import { getLangKey } from '../../../../../../../../../models/Language';
import { Dropdown } from 'antd';

const locale = getLangKey();
const icons = categoryIcons();

type Props = {
  onEmojiSelect: (emoji: { id: string; name: string; native: string }) => void;
  getContainer: () => HTMLDivElement;
  darkMode: boolean;
  emojiData: any;
};

export const EmojiPicker: FC<Props> = ({
  onEmojiSelect,
  getContainer,
  darkMode,
  emojiData
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [skinTone, _setSkinTone] = useState<SkinTone>(SkinTone.Default);

  const setSkinTone = (tone: SkinTone) => {
    Store.set('skin', tone);
    _setSkinTone(tone);
    setIsDropdownOpen(false);
  };

  return (
    <div
      className={cx(styles.pickerContainer, { [styles.darkPicker]: darkMode })}
    >
      <Picker
        data={emojiData}
        onEmojiSelect={onEmojiSelect}
        perLine={9}
        skin={skinTone}
        theme="auto"
        emojiButtonRadius="7px"
        emojiButtonSize={36}
        categoryIcons={icons}
        emojiButtonColors={[
          '#b9e98a',
          '#b5e0fe',
          '#f9f064',
          '#f3c1fd',
          '#ffe2b0',
          '#e0dfff'
        ]}
        locale={locale}
      />
      <Dropdown
        className={styles.skinToneDropdown}
        menu={{
          items: skinTones.map(({ key, label }) => ({
            key,
            label: label,
            className: cx(styles.skinToneItem, [
              styles[`skinTone-${key}`],
              { [styles.active]: skinTone === key }
            ]),
            icon: <span className={styles.skinToneIcon} />
          })),
          // @ts-expect-error
          onClick: ({ key }) => setSkinTone(key)
        }}
        onOpenChange={setIsDropdownOpen}
        open={isDropdownOpen}
        trigger={['click']}
        placement="topRight"
        overlayClassName={styles.skinToneOverlay}
        getPopupContainer={getContainer}
      >
        <div className={styles.skinToneButton} />
      </Dropdown>
    </div>
  );
};
