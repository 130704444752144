export const en = {
  edit: 'Edit',
  membersOnly: 'Members only',
  viewCommit: 'view commit',
  addReaction: 'Add reaction...',
  you: 'You',
  reactedWith: 'reacted with ',
  and: (lastUserName: string) => ` and ${lastUserName}`,
  error: 'Something went wrong, please try again.',
  plusOthers: (totalUsers: number) =>
    ` plus ${totalUsers} other${totalUsers > 1 ? 's' : ''}`
};

export type Translations = typeof en;
