import React, { FC, useState, ReactNode } from 'react';
import DetailsLabel from '../DetailsLabel';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';
import { User } from '../../../../models/User';
import { Log } from '../../../../models/Task';
import { Comment } from '../../../../models/Comment';
import Comments from './Comments';
import Logs from './Logs';
import { Button } from 'antd';
import styles from './styles.module.css';
import cx from 'classnames';
import { UpdateComment } from '../../../../../shared/updateComment';

type Props = {
  container: ReactNode;
  createComment: (commentBody: any) => void;
  deleteComment: (commentId: number) => void;
  updateComment: UpdateComment;
  commentsLoading: boolean;
  comments: Comment[];
  currentUserId: number;
  users: User[];
  usersNotOnProject: User[];
  logs: Log[];
  taskId: number;
  canEditComment: boolean;
  canViewPrivateComments: boolean;
  canCreatePrivateComments: boolean;
  canViewCommitComment: boolean;
  billingRights: boolean;
  owner: User;
  bugherdUrl: string;
  organizationId: number;
  guestVisible: boolean;
  isSmallView?: boolean;
  isAdminView?: boolean;
  isNewSidebar?: boolean;
  onPlanUpgradeClick?: () => void;
  projectId: number;
  isExpandedView?: boolean;
  apiDomain: string;
};

type Show = 'log' | 'comments';

const strings = translations[getLangKey()];

const CommentsAndLog: FC<Props> = ({
  container,
  commentsLoading,
  comments,
  currentUserId,
  users,
  usersNotOnProject,
  logs,
  taskId,
  canEditComment,
  canViewPrivateComments,
  canCreatePrivateComments,
  canViewCommitComment,
  billingRights,
  owner,
  bugherdUrl,
  organizationId,
  guestVisible,
  createComment,
  deleteComment,
  updateComment,
  isSmallView,
  isAdminView,
  isNewSidebar,
  onPlanUpgradeClick,
  projectId,
  isExpandedView,
  apiDomain
}) => {
  const [show, setShow] = useState<Show>('comments');
  const isComments: boolean = show === 'comments';

  return (
    <div
      className={cx(styles.commentsAndLogOuter, {
        [styles.commentsExpanded]: isExpandedView
      })}
    >
      <div className={styles.labelContainer}>
        <DetailsLabel
          label={isComments ? strings.comments : strings.eventLog}
        />
        <Button
          type="text"
          size="small"
          onClick={() => setShow(isComments ? 'log' : 'comments')}
          className={cx(styles.toggleButton, {
            [styles.adminToggle]: isAdminView
          })}
        >
          {isComments ? strings.viewLog : strings.viewComments}
        </Button>
      </div>
      {isComments ? (
        <Comments
          container={container}
          commentsLoading={commentsLoading}
          comments={comments}
          currentUserId={currentUserId}
          users={users}
          usersNotOnProject={usersNotOnProject}
          taskId={taskId}
          canEditComment={canEditComment}
          canViewPrivateComments={canViewPrivateComments}
          canCreatePrivateComments={canCreatePrivateComments}
          canViewCommitComment={canViewCommitComment}
          billingRights={billingRights}
          owner={owner}
          bugherdUrl={bugherdUrl}
          organizationId={organizationId}
          guestVisible={guestVisible}
          createComment={createComment}
          deleteComment={deleteComment}
          updateComment={updateComment}
          isSmallView={isSmallView}
          isAdminView={isAdminView}
          isNewSidebar={isNewSidebar}
          onPlanUpgradeClick={onPlanUpgradeClick}
          projectId={projectId}
          apiDomain={apiDomain}
        />
      ) : (
        <Logs logs={logs} isAdminView={isAdminView} />
      )}
    </div>
  );
};

export default CommentsAndLog;
