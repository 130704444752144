export const en = {
  projectSummaryEmail: 'Project Summary Email',
  taskDoneEmail: 'Task Done Email',
  save: 'Save',
  guestNotifications: 'Guest Notifications',
  guestCollaboration: 'Guest Collaboration',
  allowGuestSeeGuestsFeedback:
    'Allow Guests to see tasks created by other guests',
  allowGuestChangeTaskStatus: 'Allow Guests to change the status of a task',
  onlyAvailableOnPremium:
    'Only available on the Premium (25 users) and above plans',
  upgradeNow: 'Upgrade now',
  guestAccessWarning: 'To access these preferences, you must ',
  allowGuestsViewCommitContent:
    'Allow Guests to view git commit content from task comments'
};

export type Translations = typeof en;
