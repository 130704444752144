import React, { FC, MutableRefObject, useState } from 'react';

import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';

import { Tag, Input } from 'antd';

import styles from './index.module.css';
import HighlightedMentions from '../../../../../../javascript/components/HighlightedMentions';
import cx from 'classnames';

const strings = translations[getLangKey()];
const DESCRIPTION_CHARACTER_LIMIT = 2000;

type Props = {
  submitTask: () => void;
  description: string;
  onDescriptionChange: (description: string) => void;
  setCaretPosition: (caretPosition: number) => void;
  fullHeight: boolean;
  descriptionRef: MutableRefObject<null | HTMLTextAreaElement>;
  caretPosition: number;
  closePopoverWhenTypingContinues: () => void;
};

const Description: FC<Props> = props => {
  const {
    submitTask,
    description,
    onDescriptionChange,
    setCaretPosition,
    fullHeight,
    descriptionRef,
    caretPosition,
    closePopoverWhenTypingContinues
  } = props;
  const [showInput, setShowInput] = useState<boolean>(true);
  const [ignoreCaretPosition, setIgnoreCaretPosition] = useState<boolean>(
    false
  );
  const [wrongShortcutWarning, setWrongShortcutWarning] = useState<boolean>(
    false
  );
  const [seenShortcutWarning, setSeenShortcutWarning] = useState<boolean>(
    false
  );

  const submitKey = () =>
    navigator.platform.indexOf('Mac') > -1 ? 'control' : 'ctrl';

  const handleOnChange = (event: any) => {
    closePopoverWhenTypingContinues();
    onDescriptionChange(
      event.target.value.substring(0, DESCRIPTION_CHARACTER_LIMIT)
    );
  };

  const isEnter = (e: KeyboardEvent): boolean => e.keyCode === 13;
  const isCtrlPlusEnterOnWindows = (e: KeyboardEvent): boolean =>
    e.keyCode === 10;

  const submitShortcut = (e: KeyboardEvent) =>
    (isCtrlPlusEnterOnWindows(e) || isEnter(e)) && (e.ctrlKey || e.metaKey);

  const submitShortcutRetired = (e: KeyboardEvent) =>
    (isCtrlPlusEnterOnWindows(e) || isEnter(e)) && e.shiftKey;

  const handleKeyUp = (event: any) => {
    if (submitShortcut(event)) {
      event.preventDefault();
    }
  };

  const handleKeyDown = (event: any) => {
    if (submitShortcutRetired(event) && !seenShortcutWarning) {
      setSeenShortcutWarning(true);
      setWrongShortcutWarning(true);
      setTimeout(() => setWrongShortcutWarning(false), 3000);
    }
    if (submitShortcut(event)) {
      event.preventDefault();
      submitTask();
    }
  };

  const handleOnBlur = (event: any) => {
    setCaretPosition(event?.target?.selectionStart);
  };

  const handleOnFocus = () => {
    setShowInput(true);
  };

  const onFocus = () => {
    if (ignoreCaretPosition) return setIgnoreCaretPosition(false);
    setTimeout(
      () =>
        descriptionRef.current?.setSelectionRange?.(
          caretPosition,
          caretPosition
        ),
      100
    );
  };

  const showMentionInput = !description || showInput;

  return (
    <div className={styles.descriptionOuter}>
      <>
        <div className={cx(styles.descriptionInner)}>
          {showMentionInput ? (
            <Input.TextArea
              ref={descriptionRef}
              id="create-task-description-input"
              maxLength={DESCRIPTION_CHARACTER_LIMIT}
              autoSize={{ minRows: fullHeight ? 8 : 5, maxRows: 18 }}
              value={description}
              autoFocus
              placeholder={strings.descriptionPlaceholder}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onFocus={onFocus}
              onMouseDown={() => setIgnoreCaretPosition(true)}
              className={styles.description}
            />
          ) : (
            <div className={styles.linkifyOuter} onClick={handleOnFocus}>
              <HighlightedMentions
                {...{
                  description,
                  className: styles.commentText
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.descriptionFooter}>
          <div
            className={cx(styles.count, {
              [styles.countForMentions]: showInput || !description
            })}
          >
            {`${description.length} / ${DESCRIPTION_CHARACTER_LIMIT}`}
          </div>
          <div
            className={cx(styles.submissionShortcut, {
              [styles.shortcutVisible]: wrongShortcutWarning
            })}
          >
            <Tag>{submitKey()} + enter</Tag>
            {strings.toSubmit}
          </div>
        </div>
      </>
    </div>
  );
};

export default Description;
