import React, {
  FC,
  useRef,
  useEffect,
  useState,
  MutableRefObject
} from 'react';
import cx from 'classnames';
import { Button, Tag, Popconfirm } from 'antd';
import AtMention from './AtMention';
import PrivateToggle from './PrivateToggle';
import { canUserViewPrivateComments } from '../permissions';
import { Trash2 as DeleteIcon } from 'lucide-react';
import * as translations from './strings';
import { getLangKey } from '../../../../../../models/Language';
import { User } from '../../../../../../models/User';
import styles from './styles.module.css';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
  CommentsState,
  useCommentsState
} from '../../../../../../../providers/Comments';

const MAX_LENGTH: number = 2000;

const strings = translations[getLangKey()];

type Props = {
  initialValue?: string;
  commentId?: number;
  onCancel?: () => void;
  isPrivate?: boolean;
  showUpgrade?: boolean;
  setShowUpgrade?: (showUpgrade: boolean) => void;
  handlePrivateToggle?: (checked: boolean) => void;
};

const AddComment: FC<Props> = ({
  initialValue,
  commentId,
  onCancel,
  isPrivate,
  showUpgrade,
  setShowUpgrade,
  handlePrivateToggle
}) => {
  const {
    getContainer,
    currentUserId,
    users,
    createComment,
    deleteComment,
    updateComment,
    canViewPrivateComments,
    canCreatePrivateComments,
    billingRights,
    owner,
    bugherdUrl,
    organizationId,
    guestVisible,
    isSmallView,
    isAdminView,
    onPlanUpgradeClick,
    taskId
  } = useCommentsState() as CommentsState;
  const submitKey = () =>
    navigator.platform.indexOf('Mac') > -1 ? 'control' : 'ctrl';
  const _initialValue = initialValue || '';
  const emptyFunc = () => {};
  const _onCancel: () => void = onCancel || emptyFunc;

  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [comment, setComment] = useState<string>(_initialValue);
  const [wrongShortcutWarning, setWrongShortcutWarning] = useState<boolean>(
    false
  );

  const mentionRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const editMode: boolean = !!_initialValue;
  const hidePrivateCommentsLabel = isAdminView && isSmallView;
  const uiSize: SizeType = 'middle';
  const hasChanges: boolean = comment !== _initialValue;
  const isNewComment: boolean = !_initialValue;

  useEffect(() => {
    if (_initialValue) {
      mentionRef?.current?.focus();
    }
  });

  const handleBlur = () => {
    setHasFocus(false);
    mentionRef?.current?.blur();
    setWrongShortcutWarning(false);
    _onCancel();
  };

  const resetInput = () => setComment(_initialValue);

  const handleSubmitComment = (_comment: string) => {
    if (!_comment) return;

    const commentBody = {
      text: _comment.trim(),
      isPrivate
    };

    if (commentId) {
      updateComment({
        commentId,
        commentEvent: {
          type: 'edit',
          text: commentBody.text
        },
        taskId
      });
    } else {
      createComment(commentBody);
    }

    resetInput();
    handleBlur();
  };

  const handleSubmitShortcut = () => {
    setComment(comment.trim());
    handleSubmitComment(comment.trim());
  };

  const hasInvalidMentions = () => {
    if (!comment) return false;

    const mentions: string[] = comment.match(
      /\[([^\[]+)\]\(([\d\)]+)\)/g
    ) as string[];

    if (!mentions) return false;

    const ids: number[] = mentions.map(mention =>
      // @ts-ignore
      parseInt(mention.match(/\(([\d]+)+\)/)[1], 10)
    );

    const mentionedUsers: User[] = users.filter((user: User) =>
      ids.includes(
        typeof user.id === 'string' ? parseInt(user.id, 10) : user.id
      )
    );
    return !!mentionedUsers.find(
      (user: User) => !canUserViewPrivateComments(user)
    );
  };

  const deleteCommentConfirm = () => {
    commentId && deleteComment(commentId);
    resetInput();
    handleBlur();
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.active]: hasFocus,
        [styles.shortcutShown]: wrongShortcutWarning
      })}
    >
      <div className={styles.mentionWrapper}>
        <AtMention
          mentionRef={mentionRef}
          users={users}
          comment={comment}
          isPrivate={!!isPrivate}
          handleOnChange={setComment}
          onSubmit={handleSubmitShortcut}
          handleOnFocus={() => setHasFocus(true)}
          onWrongShortcut={() => setWrongShortcutWarning(true)}
          maxLength={MAX_LENGTH}
          guestVisible={guestVisible}
        />
      </div>
      <div className={styles.actions}>
        <div className={styles.footer}>
          {wrongShortcutWarning && (
            <div className={styles.submissionShortcut}>
              <Tag>
                {submitKey()} + {strings.enter}
              </Tag>{' '}
              {strings.toSubmit}
            </div>
          )}
          <div
            className={styles.count}
            onClick={() => {
              mentionRef?.current?.focus();
              setHasFocus(true);
            }}
          >
            {comment.length} / {MAX_LENGTH}
          </div>
        </div>
        <div className={styles.actionsInner}>
          <Button
            type="primary"
            onClick={() => handleSubmitComment(comment)}
            className={styles.roundedButtonOverride}
            size={uiSize}
            disabled={!comment.length}
            title={!comment.length ? strings.addSomeContent : undefined}
          >
            {editMode ? strings.save : strings.add}
          </Button>
          {hasChanges ? (
            <Popconfirm
              title={strings.discardChanges}
              // @ts-ignore
              getPopupContainer={getContainer}
              onConfirm={() => {
                resetInput();
                handleBlur();
              }}
            >
              <Button type="text" className={styles.cancel} size={uiSize}>
                {strings.cancel}
              </Button>
            </Popconfirm>
          ) : (
            <Button
              type="text"
              className={styles.roundedButtonOverride}
              size={uiSize}
              onClick={handleBlur}
            >
              {strings.cancel}
            </Button>
          )}
        </div>
        <div className={styles.privateAndDeleteContainer}>
          {canViewPrivateComments &&
            isNewComment &&
            handlePrivateToggle &&
            setShowUpgrade && (
              <PrivateToggle
                isPrivate={!!isPrivate}
                // @ts-ignore
                getContainer={getContainer}
                canCreatePrivateComments={canCreatePrivateComments}
                handleFocus={() => setHasFocus(true)}
                mentionRef={mentionRef}
                uiSize={uiSize}
                handlePrivateToggle={handlePrivateToggle}
                setShowUpgrade={setShowUpgrade}
                showUpgrade={!!showUpgrade}
                hasInvalidMentions={hasInvalidMentions()}
                currentUserId={currentUserId}
                organizationId={organizationId}
                billingRights={billingRights}
                ownerEmail={owner ? owner.email : undefined}
                bugherdUrl={bugherdUrl}
                hideLabel={hidePrivateCommentsLabel}
                onPlanUpgradeClick={onPlanUpgradeClick}
              />
            )}
          {editMode && (
            <div className={styles.deleteContainer}>
              <Popconfirm
                placement="topRight"
                title={strings.deleteComment}
                okText={strings.delete}
                // @ts-ignore
                getPopupContainer={getContainer}
                onConfirm={deleteCommentConfirm}
              >
                {!isNewComment && (
                  <Button
                    icon={<DeleteIcon className={styles.deleteIcon} />}
                    className={styles.delete}
                  />
                )}
              </Popconfirm>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddComment;
