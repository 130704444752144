import React, { FC, useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.css';
import { Button, Tooltip } from 'antd';
import * as translations from '../strings';
import { Reacted } from './Reacted';
import { getLangKey } from '../../../../../../../../models/Language';
import { UserReaction } from './getNewReactions';

const strings = translations[getLangKey()];

type UpdateParams = {
  reactionKey?: string;
  emoji?: { id: string; name: string; native: string; skin?: number };
};

type Props = {
  currentUser: { id: number };
  getContainer: () => HTMLDivElement;
  users: { id: number; name: string }[];
  updateReactions: (params: UpdateParams) => void;
  commentReactions: {
    [key: string]: {
      name: string;
      userReactions: UserReaction[];
    };
  };
};

export const CommentReactions: FC<Props> = ({
  currentUser,
  getContainer,
  users,
  updateReactions,
  commentReactions
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<string>('');

  return (
    <>
      {Object.keys(commentReactions)
        .filter(
          reactionKey => commentReactions[reactionKey]?.userReactions?.length
        )
        .map(reactionKey => {
          const reaction = commentReactions[reactionKey];
          let reactionUserIds: number[] = [];
          let reactionNatives: string[] = [];

          reaction.userReactions.forEach(({ users, native }) => {
            reactionUserIds = [...reactionUserIds, ...users];
            reactionNatives = [...reactionNatives, native];
          });

          const uniqueReactionUserIds = reactionUserIds.filter(
            (id, index, arr) => index === arr.indexOf(id)
          );
          const totalReactionUserIds = uniqueReactionUserIds.length;
          const isOpen = tooltipOpen === reactionKey;

          return (
            <Tooltip
              key={reactionKey}
              getPopupContainer={getContainer}
              placement="top"
              overlayClassName={styles.emojiReactionTooltip}
              trigger={['hover']}
              open={isOpen}
              onOpenChange={open => setTooltipOpen(open ? reactionKey : '')}
              title={
                <div className={styles.emojiTooltipContent}>
                  {reactionNatives.map(native => (
                    <span
                      key={native}
                      role="img"
                      className={styles.tooltipEmojiReaction}
                    >
                      {native}
                    </span>
                  ))}
                  <p className={styles.whoReacted}>
                    <Reacted
                      reactionUserIds={reactionUserIds}
                      currentUserId={currentUser?.id}
                      users={users}
                    />
                    <br />
                    <span className={styles.reactedWith}>
                      {strings.reactedWith}
                      <br />
                      {reaction?.name}
                    </span>
                  </p>
                </div>
              }
            >
              <Button
                type="text"
                className={cx(styles.emojiButton, {
                  [styles.reactedTo]: reactionUserIds.includes(currentUser?.id)
                })}
                size="small"
                onClick={() => updateReactions({ reactionKey })}
              >
                <span role="img" className={styles.reactionEmoji}>
                  {reactionNatives.map(native => (
                    <span key={native}>{native}</span>
                  ))}
                  <span className={styles.reactionCount}>
                    {totalReactionUserIds < 100 ? totalReactionUserIds : '99+'}
                  </span>
                </span>
              </Button>
            </Tooltip>
          );
        })}
    </>
  );
};
