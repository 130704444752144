import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  Select,
  message as antmessage
} from 'antd';
// eslint-disable-next-line
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { checkForExtension } from 'jsUtilities/checkForBugherdExtension';
import styles from './index.module.css';
import { post } from 'utils/fetch';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';
import { countryCodes } from 'appJS/components/CountryCode/index';
import Header from 'appJS/components/user_header';
import cx from 'classnames';
import { Industry } from 'appJS/views/kanban/Onboarding/userSurveyOptions';
import { addCelloUccToFields } from '../shared/addCelloUccToFields';

const strings = translations[getLangKey()];

type Profile = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  organization_id: string | null;
  connection_id: string;
  connection_type: string;
  idp_id: string;
  // eslint-disable-next-line
  raw_attributes: Record<string, null>;
};

export default ({
  profile,
  authenticityToken,
  cookies
}: {
  profile: Profile;
  authenticityToken: string;
  cookies: { [key: string]: string };
}) => {
  if (profile === null) {
    window.location.href = '/users/registrations/new';
  }
  const [loading, setLoading] = useState<boolean>(false);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean>(
    false
  );
  const [countryCode, setCountryCode] = useState<string | undefined>('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [checkboxChecked, setChecked] = useState<boolean>(false);
  const [form] = Form.useForm();
  const {
    first_name,
    last_name,
    email,
    connection_type,
    connection_id
  } = profile;

  const translateCookies = () => {
    const keys = Object.keys(cookies);

    return keys.map(key => {
      if (key === 'user_agent') {
        return [
          'landing_user_agent',
          cookies[key] || encodeURIComponent(navigator.userAgent)
        ];
      }

      return ['landing_' + key, cookies[key]];
    });
  };

  const hiddenInputs = [
    ['real', 'yes'],
    ['terms', '1'],
    ['signup_segment', ''],
    ['commit', 'sign up for free'],
    ['email', email],
    ['name', first_name],
    ['surname', last_name],
    ['workos_id', connection_id],
    ['workos_provider', connection_type],
    ['password', 'has_sso'],
    // eslint-disable-next-line new-cap
    ['time_zone', Intl.DateTimeFormat().resolvedOptions().timeZone],
    ...translateCookies()
  ];

  useEffect(() => {
    (async () => {
      const exists = await checkForExtension();

      if (exists) {
        // @ts-expect-error
        setIsExtensionInstalled(exists);
      }
    })();
  }, []);

  const handleFinish = async (values: any) => {
    setLoading(true);

    // This happens at the last moment to give Cello all the time in the world to load
    values = await addCelloUccToFields(values);

    post('/users/registrations', {
      utf8: '✓',
      authenticity_token: authenticityToken,
      user: { extension_installed: isExtensionInstalled, ...values }
    })
      .then(body => {
        if (body.error && body.error.message) {
          throw Error(body.error.message);
        } else {
          window.location.href = body.redirect_to;
        }
      })
      .catch(({ message, status }) => {
        setLoading(false);
        if (message === 'HTTP error! status: 409') {
          antmessage.error(
            <div>
              {strings.accountAlreadyExists}
              <a href="/users/sign_in">{strings.logInHere}</a>.
            </div>,
            5
          );
        } else if (message === 'HTTP error! status: 500') {
          antmessage.error(strings.somethingWentWrong, 5);
        } else {
          antmessage.error(message, 5);
        }
      });
  };

  const buildHiddenInputs = () => {
    return hiddenInputs.map(info => {
      return (
        <Form.Item hidden name={info[0]} key={info[0]}>
          <Input type="hidden" />
        </Form.Item>
      );
    });
  };

  const getInitialValues = () => {
    const values = {};

    hiddenInputs.forEach(input => {
      // @ts-ignore
      values[input[0]] = input[1];
    });

    return values;
  };

  const handleSelectChange = value => {
    setCountryCode(value);
  };

  const handleInputChange = e => {
    setPhoneNumber(e.target.value);
  };

  const onSubmit = () => {
    form.setFieldsValue({
      phone_number: (countryCode || '') + (phoneNumber || '')
    });
  };

  const checkCheckbox = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  return (
    <div className={styles.pageWrapper}>
      <Header />
      <div className={styles.formWrapper}>
        <div className={styles.formMiddle}>
          <h1 className={styles.title}>
            {strings.welcome} {first_name ?? ''}!
          </h1>
          <Form
            className={styles.form}
            name="login"
            onFinish={handleFinish}
            layout="vertical"
            initialValues={getInitialValues()}
            form={form}
          >
            <Form.Item
              label="What’s your Company name?"
              name="initial_organization_name"
              rules={[
                {
                  required: true,
                  message: strings.organizationNameErrorMessage
                }
              ]}
            >
              <Input placeholder="Your company" />
            </Form.Item>

            <Form.Item
              label="Organization type"
              name="industry"
              rules={[
                { required: true, message: strings.organizationTypeErrorMessage }
              ]}
            >
              <Select
                placeholder="Please select"
                options={Industry}
              />
            </Form.Item>

            <Row gutter={10} align="top">
              <Col className={styles.countrySelect} span={8}>
                <Form.Item
                  label="Phone:"
                  name="country_code"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (phoneNumber && !value) {
                          return Promise.reject(strings.countryCodeError);
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                  dependencies={['phone_without_code']}
                >
                  <Select
                    placeholder="+1"
                    optionLabelProp="value"
                    options={countryCodes}
                    className={styles.countrySelect}
                    onChange={handleSelectChange}
                    dropdownStyle={{ width: '300px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  label=" "
                  name="phone_without_code"
                  rules={[
                    { required: false },
                    { max: 20, message: strings.max20Characters },
                    {
                      pattern: /^[0-9*#\-\(\)+\s]+$/,
                      message: strings.phoneNumberErrorMessage
                    }
                  ]}
                  validateTrigger="onChange"
                  validateDebounce={0.3}
                >
                  <Input placeholder="Optional" onChange={handleInputChange} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="phone_number" className={styles.hideVisibilty}>
              <Input type="tel" />
            </Form.Item>

            <Form.Item>
              <Checkbox onChange={checkCheckbox} name="newsletter">
                <div
                  className={cx(styles.subscribeCopy, {
                    [styles.subscribeDark]: checkboxChecked
                  })}
                >
                  {strings.tickToSubscribe}
                </div>
              </Checkbox>
            </Form.Item>

            {buildHiddenInputs()}

            <p className={styles.disclaimer}>
              {strings.bySigningUp}
              <a
                href="https://get.bugherd.com/terms"
                target="_blank"
                rel="noopener"
              >
                {strings.termsOfService}
              </a>
              {strings.and}
              <a
                href="https://get.bugherd.com/privacy"
                target="_blank"
                rel="noopener"
              >
                {strings.privacyPolicy}
              </a>
              .
            </p>

            <Form.Item className={styles.submit}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                onClick={onSubmit}
              >
                {strings.continue}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className={styles.quoteWrapper}>
          <div className={styles.socialProof}>
            <p>
              {strings.rachelQuote}&nbsp;
              <br />
              <strong>{strings.rachelTitle}</strong>
            </p>
            <div>
              <span className={styles.profilePhoto} />
              <span className={styles.stars}>★★★★★</span>
            </div>
          </div>

          <ul className={styles.list}>
            <li>{strings.noCreditCardUpfront}</li>
            <li>{strings.trialBugherdPremium}</li>
            <li>{strings.getStartedInMinutes}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
