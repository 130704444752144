export enum SkinTone {
  Default = '1',
  Light = '2',
  MediumLight = '3',
  Medium = '4',
  MediumDark = '5',
  Dark = '6'
}

export const skinTones = [
  {
    key: SkinTone.Default,
    label: 'Default'
  },
  {
    key: SkinTone.Light,
    label: 'Light'
  },
  {
    key: SkinTone.MediumLight,
    label: 'Medium-Light'
  },
  {
    key: SkinTone.Medium,
    label: 'Medium'
  },
  {
    key: SkinTone.MediumDark,
    label: 'Medium-Dark'
  },
  {
    key: SkinTone.Dark,
    label: 'Dark'
  }
];

export const skinToneModifiers = [
  '',
  '\u{1f3fb}',
  '\u{1f3fc}',
  '\u{1f3fd}',
  '\u{1f3fe}',
  '\u{1f3ff}'
];
