import React, { FC, Reducer, useEffect, useReducer } from 'react';
import styles from './styles.module.css';
import { checkForExtension } from '../../utils/checkForBugherdExtension';
import usePusherSubscribe from '../../hooks/usePusherSubscribe';
import { Event, Subscription } from '../../models/Pusher';
import { Spin, message, Button, Alert } from 'antd';
import { get } from '../../utils/fetch';
import sharingExtensionImage from './sharing_extension.png';
import mobileNoJavascriptImage from './mobile-no-javascript-image.png';
import { AntdWrapper } from '../../../clients/shared/AntdWrapper';
import { updateExtensionProjectsList } from './extensionMessaging';
import AssetBanner from '../design_feedback/AssetBanner';
import { SharingInstall } from './install';
import { getIsMobileDevice } from './getIsMobileDevice';
import { isMobile } from 'react-device-detect';

type Props = {
  project: {
    id: number;
    pusherChannelName: string;
  };
  projectSite: {
    id: number;
    url: string;
  };
  pusherChannelAuthEndpoint: string;
  pusherApiKey: string;
  validateSiteJsEndpoint: string;
  isProjectUser: boolean;
  isOrganizationUser: boolean;
  installed: boolean;
  projectOwner: {
    name: string;
    email: string;
    id: number;
  };
  recentlyAddedToProject: boolean;
};

const checkForScript = async (validateSiteJsEndpoint: string) => {
  message.info('Checking for BugHerd script...', 4);
  const response = await get(validateSiteJsEndpoint);
  if (response.status !== 'ok') {
    message.error(
      'Something went wrong while checking for BugHerd script, please refresh to try again.',
      4
    );
  }
};

type SharingCheckState =
  | 'initial'
  | 'checking'
  | 'invite_modal'
  | 'extension_not_found'
  | 'script_not_found'
  | 'install_extension_modal'
  | 'timed_out'
  | 'success';
type SharingStateActions =
  | 'CHECK_FOR_BUGHERD'
  | 'SHOW_INVITE'
  | 'EXTENSION_CHECK_TIMED_OUT'
  | 'SCRIPT_FOUND'
  | 'SCRIPT_NOT_FOUND'
  | 'EXTENSION_FOUND'
  | 'EXTENSION_NOT_FOUND'
  | 'CONTINUE_TO_PROJECT_SITE';

export const Sharing: FC<Props> = ({
  project,
  projectSite,
  pusherChannelAuthEndpoint,
  pusherApiKey,
  validateSiteJsEndpoint,
  isProjectUser,
  isOrganizationUser,
  installed,
  projectOwner,
  recentlyAddedToProject
}) => {
  const { id, pusherChannelName } = project;

  const reducer: Reducer<SharingCheckState, SharingStateActions> = (
    state,
    action
  ) => {
    console.log({
      state,
      action
    });

    const assertNever = (state: never): void => {
      throw new Error(`${state} not handled`);
    };

    switch (state) {
      case 'initial':
        if (action === 'SHOW_INVITE') return 'invite_modal';
        if (action === 'CHECK_FOR_BUGHERD') return 'checking';
        if (action === 'EXTENSION_CHECK_TIMED_OUT') return 'timed_out';
        if (action === 'CONTINUE_TO_PROJECT_SITE') return 'success';
        break;
      case 'checking':
        if (action === 'CONTINUE_TO_PROJECT_SITE') return 'success';
        if (action === 'EXTENSION_CHECK_TIMED_OUT') return 'timed_out';
        if (action === 'EXTENSION_FOUND') return 'success';
        if (action === 'SCRIPT_FOUND') return 'success';
        if (action === 'EXTENSION_NOT_FOUND') return 'extension_not_found';
        if (action === 'SCRIPT_NOT_FOUND') return 'script_not_found';
        break;
      case 'extension_not_found':
        if (action === 'CONTINUE_TO_PROJECT_SITE') return 'success';
        if (action === 'SCRIPT_FOUND') return 'success';
        if (action === 'SCRIPT_NOT_FOUND') return 'install_extension_modal';
        break;
      case 'script_not_found':
        if (action === 'EXTENSION_CHECK_TIMED_OUT') return 'timed_out';
        if (action === 'CONTINUE_TO_PROJECT_SITE') return 'success';
        if (action === 'EXTENSION_FOUND') return 'success';
        if (action === 'EXTENSION_NOT_FOUND') return 'install_extension_modal';
        break;
      case 'timed_out':
        if (action === 'CONTINUE_TO_PROJECT_SITE') return 'success';
        if (action === 'SCRIPT_FOUND') return 'success';
        break;
      case 'install_extension_modal':
      case 'success':
      case 'invite_modal':
        if (action === 'EXTENSION_CHECK_TIMED_OUT') return state;
        break;
      default:
        assertNever(state);
    }
    console.error(`Unhandled action:`, { state, action });
    return state;
  };

  const [state, dispatch] = useReducer(reducer, 'initial');
  const handleSiteJSVerified = ({ project_site: { id } }) => {
    if (id === projectSite.id) {
      message.success('BugHerd script installed', 4);
      dispatch('SCRIPT_FOUND');
    }
  };

  const handleSiteJSVerificationFailed = ({ project_site: { id } }) => {
    if (id === projectSite.id) {
      message.warning('BugHerd script not installed', 4);
      dispatch('SCRIPT_NOT_FOUND');
    }
  };

  useEffect(() => {
    if (state === 'success') {
      const projectSiteURL = new URL(projectSite.url);
      window.location.href = projectSiteURL.href;
    }
  }, [state]);

  usePusherSubscribe({
    pusher: {
      config: {
        pusherApiKey,
        pusherChannelAuthEndpoint
      },
      projects: [{ id: id.toString(), pusherChannelName }],
      events: [
        {
          name: Event.SITE_JS_VERIFIED,
          onUpdate: handleSiteJSVerified
        },
        {
          name: Event.SITE_JS_NOT_VERIFIED,
          onUpdate: handleSiteJSVerificationFailed
        }
      ],
      subscription: Subscription.SHARING
    },
    loading: false
  });

  const handleCheckForExtension = async () => {
    message.info('Checking for BugHerd extension...', 4);
    const exists = await checkForExtension();
    if (exists) {
      message.success('BugHerd extension found', 4);
      if (isProjectUser) {
        if (recentlyAddedToProject) {
          return updateExtensionProjectsList(() =>
            dispatch('CONTINUE_TO_PROJECT_SITE')
          );
        } else {
          return dispatch('CONTINUE_TO_PROJECT_SITE');
        }
      } else {
        window.location.href += '?installed=true';
        return;
      }
    }
    message.warning('BugHerd extension not found', 4);
    dispatch('EXTENSION_NOT_FOUND');
  };

  useEffect(() => {
    if (!isProjectUser && !recentlyAddedToProject && isOrganizationUser) {
      dispatch('SHOW_INVITE');
    } else if (!installed) {
      dispatch('CHECK_FOR_BUGHERD');
      checkForScript(validateSiteJsEndpoint);
      handleCheckForExtension();
    } else {
      updateExtensionProjectsList(() => dispatch('CONTINUE_TO_PROJECT_SITE'));
    }

    const timeout = setTimeout(() => {
      dispatch('EXTENSION_CHECK_TIMED_OUT');
    }, 10_000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const InstallExtension = ({}) => {
    return (
      <div className={styles.installExtension}>
        <img
          src={sharingExtensionImage}
          className={styles.sharingExtensionImage}
          alt="sharing_extension_image"
        />
        <div className={styles.messaging}>
          <h2>
            To give feedback with BugHerd, you'll need a browser extension.
          </h2>
          <p className={styles.clickOne}>
            Click one of the links below to add it to your browser. 👇
          </p>
        </div>
        <SharingInstall />
      </div>
    );
  };

  const MobileNoJavascript = ({}) => {
    return (
      <div className={styles.noJavascript}>
        <Alert
          showIcon
          type="warning"
          message="Javascript code not detected"
          closable={false}
          className={styles.alert}
        />
        <h2>
          To give feedback with BugHerd on mobile, this website will need the
          Javascript code installed.
        </h2>
        <span className={styles.contactOwner}>
          Contact the owner of this BugHerd project.
        </span>
        <p>
          Alternatively you can view this link on a desktop device, which won't
          require a Javascript code. All you will need is to get the BugHerd
          browser extension.{' '}
          <Button type="link" href="/'" className={styles.learnMore}>
            Learn more
          </Button>
        </p>
        <img
          src={mobileNoJavascriptImage}
          className={styles.noJavascriptImage}
          alt="mobile_no_javascript_image"
        />
      </div>
    );
  };

  const showLoading = [
    'initial',
    'checking',
    'extension_not_found',
    'script_not_found'
  ].includes(state);

  const isMobileDevice = getIsMobileDevice() && isMobile;

  return (
    <AntdWrapper>
      <div className={styles.sharingContainer}>
        <div className={styles.sharingContent}>
          {showLoading ? (
            <Spin size="large" />
          ) : isMobileDevice ? (
            <MobileNoJavascript />
          ) : (
            state === 'install_extension_modal' && <InstallExtension />
          )}
        </div>
        {state === 'invite_modal' && (
          <div className={styles.installExtension}>
            <img
              src={sharingExtensionImage}
              className={styles.sharingExtensionImage}
              alt="sharing_extension_image"
            />
            <AssetBanner
              owner={projectOwner}
              assetIdOrGroup={''}
              projectId={project.id}
              // @ts-expect-error
              container={document.getElementById('sharing_container')}
              loggedIn
              toProjects
            />
          </div>
        )}
        {state === 'timed_out' && <ContinueToProjectSite dispatch={dispatch} />}
      </div>
    </AntdWrapper>
  );
};

type ContinueToProjectSiteProps = {
  dispatch: (action: SharingStateActions) => void;
};
const ContinueToProjectSite: React.FC<ContinueToProjectSiteProps> = ({
  dispatch
}) => {
  return (
    <div className={styles.messaging}>
      <h2>
        We encountered an issue when checking that BugHerd has been setup
        correctly.
      </h2>
      <p className={styles.clickOne}>
        Feel free to <a href="mailto:support@bugherd.com">contact us</a> if this
        issue persists.
      </p>
      <Button
        type={'primary'}
        onClick={() => dispatch('CONTINUE_TO_PROJECT_SITE')}
      >
        Continue to project site
      </Button>
    </div>
  );
};
