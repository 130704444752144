import { put } from '../../javascript/utils/fetch';
import { Comment } from '../sidebar/models/Task';
import { Comment as KanbanComment } from 'appJS/hooks/useComments';

export type CommentEvent = {
  type: 'edit' | 'read' | 'reaction';
  text?: string;
  reactions?: string;
};

export type CommentParams = {
  commentId: number;
  taskId?: number;
  commentEvent: CommentEvent;
};

export type UpdateComment = (params: CommentParams) => void;

export const updateComment = async ({
  commentId,
  setComments,
  comments,
  endpoint,
  commentEvent: { type, text, reactions }
}: {
  commentId: number;
  setComments: (comments: Comment[] | KanbanComment[]) => void;
  comments: Comment[] | KanbanComment[];
  endpoint: string;
  commentEvent: CommentEvent;
}) => {
  const commentsClone = comments.slice();
  const commentsToEdit = commentsClone.slice();
  const commentToUpdate = commentsToEdit.find(
    ({ id }: Comment | KanbanComment) => commentId === id
  );
  if (!commentToUpdate) return;

  let body = {};
  switch (type) {
    case 'edit':
      body = { text };
      break;
    case 'read':
      body = { event: 'read' };
      break;
    case 'reaction':
      body = { reactions };
      break;
  }

  if (type !== 'read') {
    Object.assign(commentToUpdate, body);
  }

  setComments(commentsToEdit);

  try {
    await put(endpoint, body);
  } catch (error) {
    console.error(error);
    setComments(commentsClone);
  }
};
