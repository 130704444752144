import { MutableRefObject } from 'react';

export const handleEmojiSelect = ({
  emoji,
  description,
  caretPosition,
  onDescriptionChange,
  setCaretPosition,
  descriptionRef
}: {
  emoji: { native: string };
  description: string;
  caretPosition: number;
  onDescriptionChange: (description: string) => void;
  setCaretPosition: (caretPosition: number) => void;
  descriptionRef: MutableRefObject<HTMLTextAreaElement | null>;
}) => {
  const currentCaretPosition = caretPosition;
  const currentDescriptionLength = description.length;
  const newDescription = `${description.slice(0, caretPosition)}${
    emoji.native
  }${description.slice(caretPosition)}`;

  onDescriptionChange(newDescription);
  const newCaretPosition =
    currentCaretPosition + (newDescription.length - currentDescriptionLength);
  if (newDescription.length > currentDescriptionLength) {
    setCaretPosition(newCaretPosition);
  }
  if (descriptionRef.current?.click) descriptionRef.current.click();
};
