import React from 'react';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Button, message } from 'antd';
import CustomLogo from './custom_logo';
import { antdForm } from '../../../utils/propTypes';
import styles from './index.module.css';
import ArrowUp from './../../../../clients/sidebar/assets/images/ic-upgrade-circle.svg';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';
import { CheckCircle } from 'lucide-react';

const strings = translations[getLangKey()];

class General extends React.Component {
  static propTypes = {
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        organization: PropTypes.shape({
          name: PropTypes.string.isRequired,
          business_tax_number: PropTypes.string,
          api_key: PropTypes.string,
          custom_logo_dark_background: PropTypes.string,
          custom_logo_light_background: PropTypes.string
        }),
        subscriptionLink: PropTypes.string.isRequired
      })
    ),
    orgId: PropTypes.number.isRequired,
    authenticityToken: PropTypes.string.isRequired,
    onOrganizationChange: PropTypes.func.isRequired,
    form: antdForm,
    access_to: PropTypes.arrayOf(
      PropTypes.shape({
        billing: PropTypes.bool.isRequired
      })
    )
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.orgId !== nextProps.orgId) {
      this.props.form.setFieldsValue({
        name: this.organization(nextProps).organization.name,
        business_tax_number: this.organization(nextProps).organization
          .business_tax_number
      });
      this.setState({
        customLogoDarkBackground: this.organization(nextProps).organization
          .custom_logo_dark_background,
        customLogoLightBackground: this.organization(nextProps).organization
          .custom_logo_light_background
      });
    }
  }

  organization = (props = this.props) => {
    return props.organizations.find(org => org.organization.id === props.orgId);
  };

  state = {
    saving: false,
    customLogoDarkBackground: this.organization().organization
      .custom_logo_dark_background,
    customLogoLightBackground: this.organization().organization
      .custom_logo_light_background,
    apiKey: this.organization().organization.api_key,
    exportDisabled: false
  };

  getAndSaveCustomLogos = (key, value) => {
    this.setState({ [key]: value }, () => {
      this.submit();
    });
    this.updateProps(key, value);
  };

  exportBugs = async () => {
    this.setState({ exportDisabled: true });

    fetch(`/organizations/${this.props.orgId}/export`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  };

  submit = event => {
    const { customLogoLightBackground, customLogoDarkBackground } = this.state;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (event) event.preventDefault();
        const newName = this.props.form.getFieldsValue(['name']).name;
        const newBTN = this.props.form.getFieldsValue(['business_tax_number'])
          .business_tax_number;

        const organization = {
          name: newName.length ? newName : null,
          custom_logo_light_background: customLogoLightBackground,
          custom_logo_dark_background: customLogoDarkBackground
        };

        if (newBTN && newBTN.length) {
          organization.business_tax_number =
            newBTN && newBTN.length ? newBTN : null;
        }

        const body = {
          utf8: '✓',
          organization,
          commit: 'save',
          authenticity_token: this.props.authenticityToken
        };

        this.setState({
          saving: true
        });

        fetch(`/organizations/${this.props.orgId}`, {
          method: 'PATCH',
          body: JSON.stringify(body),
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            this.setState({
              saving: false
            });

            if (response.status === 200) {
              this.props.onOrganizationChange(this.props.orgId, organization);
              message.success('Updated organization');
            } else {
              throw Error(response.statusText);
            }
          })
          .catch(error =>
            message.error('Something went wrong, please try again')
          );
      }
    });
  };

  resetApiKey = () => {
    fetch(`/settings/organizations/${this.props.orgId}/reset_api_key`)
      .then(response => response.json())
      .then(() => {
        message.info('Reset API key successfully!');
        window.location.reload();
      });
  };

  updateProps = (key, value) => {
    const _key = key.includes('Light')
      ? 'custom_logo_light_background'
      : 'custom_logo_dark_background';
    window.settings_props.organizations.find(
      org => org.organization.id === this.organization().organization.id
    ).organization[_key] = value;
  };

  renderSingleSignOn = (organization, owner) => {
    const setup = () => {
      if (owner) {
        return (
          <span>
            <p>{strings.setupSsoBelow}</p>
            <p>
              {strings.setupLink}
              <a href={organization.workos_setup_url}>
                {strings.clickHereToBegin}
              </a>{' '}
              ({strings.expiresIn24Hours})
            </p>
          </span>
        );
      }
    };

    return (
      <div>
        <h3 className={styles.ssoHeader}>{strings.sso}</h3>
        <div className={styles.ssoInfo}>
          {setup()}
          <p className={styles.orgContainer}>
            {strings.organizationUrl}
            <Input
              disabled
              className={styles.orgInput}
              placeholder="your-organization-url"
              value={organization.sso_organization_url}
              addonBefore="www.bugherd.com/"
              addonAfter={
                <Button
                  type="primary"
                  className={styles.copyButton}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `${organization.sso_organization_url}`
                    )
                  }
                >
                  {strings.copy}
                </Button>
              }
            />
          </p>
        </div>
      </div>
    );
  };

  renderSingleSignOnUpgrade = () => {
    return (
      <div className={styles.upgradeContainer}>
        <h3 className={styles.ssoHeader}>
          {strings.sso} <div className={styles.ssoUpgradeImage} />
        </h3>
        <div className={styles.ssoContainer}>
          <div className={styles.ssoTextContainer}>
            <h3>
              <ArrowUp className={styles.upgradeArrow} />
              {strings.ssoForBugherd}
            </h3>
            <p className={styles.ssoText1}>{strings.largeOrganisationSetup}</p>
            <p>{strings.ssoDescription}</p>
            <p>{strings.availableOnEnterprisePlan}</p>
            <p>
              <a href="https://bugherd.typeform.com/to/OcoH14?typeform-source=bugherd.com">
                {strings.contactUsForPricing}
              </a>
            </p>
          </div>
          <div className={styles.ssoImageContainer}>
            <div className={styles.ssoImage} />
          </div>
        </div>
      </div>
    );
  };

  renderExportBugsSuccess = () => {
    if (this.state.exportDisabled) {
      return (
        <div>
          <br />
          <CheckCircle
            className={styles.checkCircle}
            width={16}
            height={16}
          />{' '}
          Success! We're generating your CSV export. Check your email shortly.
          <br />
        </div>
      );
    }
  };

  renderExportBugs = () => {
    const { exportDisabled } = this.state;

    return (
      <div>
        <h3>Export Organization Bugs</h3>
        <div className={styles.exportContainer}>
          <p>
            Export all of the bugs for your organization. This will be delivered
            in csv format to your current email address.
            <br />
            <Button
              className={styles.saveButton}
              onClick={this.exportBugs}
              disabled={exportDisabled}
            >
              Export
            </Button>
            {this.renderExportBugsSuccess()}
          </p>
        </div>
      </div>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const accessTo = this.props.access_to.find(
      value => value.organization_id === this.props.orgId
    );
    const organization = this.organization();
    const org = organization.organization;
    const { customLogoLightBackground, customLogoDarkBackground } = this.state;
    const owner = organization.users.find(user => user.owner === true);

    return (
      <div className={styles.container}>
        <h3 className={styles.tabHeader}>{strings.accountDetails}</h3>
        <Form
          className={styles.form}
          layout="vertical"
          onSubmit={this.submit}
          hideRequiredMark
        >
          <Form.Item label="Account Name">
            {getFieldDecorator('name', {
              initialValue: org.name,
              rules: [
                {
                  type: 'string'
                },
                {
                  required: true,
                  message: 'An account name is required'
                }
              ]
            })(
              <Input
                name="organization[name]"
                className={styles.accountName}
                onChange={event =>
                  this.props.form.setFieldsValue({ name: event.target.value })
                }
              />
            )}
          </Form.Item>
          {accessTo.billing && (
            <Form.Item label="ABN/VAT#">
              {getFieldDecorator('business_tax_number', {
                initialValue: org.business_tax_number || '',
                rules: [
                  {
                    type: 'string'
                  }
                ]
              })(
                <Input
                  name="organization[business_tax_number]"
                  className={styles.abnVat}
                  onChange={event =>
                    this.props.form.setFieldsValue({
                      business_tax_number: event.target.value
                    })
                  }
                />
              )}
            </Form.Item>
          )}
          <CustomLogo
            upgradeEndpoint={organization.subscriptionLink}
            organization={org}
            accessTo={accessTo}
            owner={owner}
            endpoint={organization.endpoints.customLogoUrl}
            getAndSaveCustomLogos={this.getAndSaveCustomLogos}
            logoLightBackground={customLogoLightBackground}
            logoDarkBackground={customLogoDarkBackground}
          />
          <h3 className={styles.authToken}>{strings.authenticationToken}</h3>
          <p className={styles.apiInfo}>
            {strings.apiKeySpecificAndFullAccess}
          </p>
          <div className={styles.apiBox}>
            <Input className={styles.apiInput} readOnly value={org.api_key} />
            <span
              className={styles.copy}
              onClick={() => {
                message.info('Copied to clipboard!');
                navigator.clipboard.writeText(`${org.api_key}`);
              }}
            >
              {strings.copy}
            </span>
            <span
              className={styles.copy}
              onClick={() => {
                this.resetApiKey();
              }}
            >
              {strings.reset}
            </span>
          </div>
          <div className={styles.apiNote}>
            <div className={styles.iconBox}>
              <InfoCircleOutlined
                style={{
                  color: '#406CFF',
                  fontSize: '16px',
                  padding: '2px 0 0 0'
                }}
              />
            </div>
            <span>
              {` This API key is for integrations only. See our `}
              <a className={styles.apiDocs} href="/api_v2">
                {strings.apiDocumentation}
              </a>
              {` for more details.`}
            </span>
          </div>

          {organization.ssoEnabled
            ? this.renderSingleSignOn(organization, owner)
            : this.renderSingleSignOnUpgrade()}

          {organization.bulkExportEnabled ? this.renderExportBugs() : null}

          <Form.Item className={styles.formButton}>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              loading={this.state.saving}
            >
              {strings.save}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(General);
