import React, { FC, useState, useRef, useEffect } from 'react';
import styles from './styles.module.css';
import { SmilePlus } from 'lucide-react';
import { Tooltip, Button, Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { EmojiPicker } from './EmojiPicker';
import useMediaQuery from '../../../../../../../../CreateTask/create_task/useMediaQuery';
import cx from 'classnames';
import { useInitEmojiData } from '../useInitEmojiData';

type Props = {
  getContainer: () => HTMLDivElement;
  onEmojiSelect: (emoji: { id: string; name: string; native: string }) => void;
  tooltipTitle: string;
  placement: TooltipPlacement;
  setPopoverOpen: (open: boolean) => void;
  popoverOpen: boolean;
  reactionPicker?: boolean;
  apiDomain: string;
};

export const PickerButton: FC<Props> = ({
  getContainer,
  onEmojiSelect,
  placement,
  tooltipTitle,
  setPopoverOpen,
  popoverOpen,
  reactionPicker,
  apiDomain
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [emojiData, setEmojiData] = useState<any>(null);
  const pickerButtonRef = useRef<HTMLButtonElement | null>(null);
  const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useInitEmojiData({ setEmojiData, emojiData, apiDomain });

  useEffect(() => {
    if (popoverOpen) setShowTooltip(false);
  }, [popoverOpen]);

  if (!emojiData) return null;

  return (
    <Popover
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      trigger={['click']}
      destroyTooltipOnHide
      placement={placement}
      overlayClassName={cx(styles.reactionPopover, {
        [styles.darkMode]: isDarkMode
      })}
      arrow={{ pointAtCenter: true }}
      content={
        <EmojiPicker
          onEmojiSelect={onEmojiSelect}
          getContainer={getContainer}
          darkMode={isDarkMode}
          emojiData={emojiData}
        />
      }
      getPopupContainer={getContainer}
    >
      <Tooltip
        title={tooltipTitle}
        placement="topRight"
        overlayClassName={styles.reactionTooltip}
        trigger={['hover']}
        open={showTooltip}
        onOpenChange={setShowTooltip}
        getPopupContainer={getContainer}
      >
        <Button
          type="text"
          ref={pickerButtonRef}
          icon={<SmilePlus className={styles.reactionIcon} />}
          className={cx(styles.reactionButton, {
            [styles.reactionPickerButton]: reactionPicker,
            [styles.descriptionPickerButton]: !reactionPicker
          })}
          size="small"
          onClick={() => setPopoverOpen(!popoverOpen)}
        />
      </Tooltip>
    </Popover>
  );
};
