import React, { FC, useEffect, useState } from 'react';
import Tour from 'reactour';
import Confetti from 'react-dom-confetti';
import { Button } from 'antd';
import { post } from 'utils/fetch';
import useMediaQuery from '../../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';

const config = {
  angle: 45,
  spread: 130,
  startVelocity: 20,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 2000,
  stagger: 3,
  width: '5px',
  height: '5px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
};

const taskElement =
  '[data-intercom-target="Task"]:not([data-rbd-drag-handle-draggable-id="null"])';
const taskDetailsElement = '#task_details_container';
const getExtensionElement = '#get-extension-container';
const gettingStartedElement = '#nav-item-getting-started';

type Props = {
  projectId: number | null;
  closeTask: () => void;
  setActiveTask: (activeTask: number) => void;
};

type Step = {
  selector: string;
  content: React.JSX.Element;
  action?: (node: Node) => void;
  active: boolean;
  showButtons?: boolean;
};

const ProjectTour: FC<Props> = ({ projectId, closeTask, setActiveTask }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const [activeSteps, setActiveSteps] = useState<Step[]>([]);
  const isMobileView = useMediaQuery('(max-width: 992px)');

  const goToNextTourStep = () => setTourStep(prevTourStep => prevTourStep + 1);
  const goToPrevTourStep = () => setTourStep(prevTourStep => prevTourStep - 1);

  const isKanbanPage =
    window.location.pathname.includes('kanban') ||
    window.location.pathname.includes('tasks');

  const handleFinishTour = async () => {
    localStorage.setItem('finished_project_onboarding', 'true');
    try {
      await post(
        `/projects/${projectId}/complete_project_board_onboarding`,
        {}
      );
    } catch (error) {
      console.error({ error });
    }
  };

  const getTourSteps = (openConfetti = false) => {
    return [
      {
        selector: taskElement,
        content: (
          <>
            <h2>💡 Here's your feedback!</h2>
            <p>
              It's now a task in your project board and includes a screenshot
              and other metadata captured by BugHerd.
            </p>
            <p>
              <strong>Click it to see more.</strong>
            </p>
          </>
        ),
        action: node => {
          const handleShowTaskDetailsTour = e => {
            e.preventDefault();
            e.stopPropagation();
            setActiveTask(node.getAttribute('data-task-id'));
            goToNextTourStep();
            node.removeEventListener('click', handleShowTaskDetailsTour);
            node.removeEventListener('mousedown', handleShowTaskDetailsTour);
          };
          node.addEventListener('click', handleShowTaskDetailsTour);
          node.addEventListener('mousedown', handleShowTaskDetailsTour);
        },
        active: !!document.querySelector(taskElement)
      },
      {
        selector: taskDetailsElement,
        content: (
          <>
            <h2>💡 View your feedback</h2>
            <p>
              BugHerd has captured a screenshot, the URL of the page, plus
              additional info like the browser details and operating system.
            </p>
          </>
        ),
        active: !!document.querySelector(taskDetailsElement),
        showButtons: true
      },
      {
        selector: getExtensionElement,
        content: (
          <>
            <h2>💡 Get the BugHerd extension</h2>
            <p>
              To use BugHerd on any website, you’ll need a browser extension.
            </p>
          </>
        ),
        action: node => {
          const handleOnClick = () => {
            goToNextTourStep();
            node.removeEventListener('click', handleOnClick);
          };
          node.addEventListener('click', handleOnClick);
        },
        active: !!document.querySelector(getExtensionElement),
        showButtons: true
      },
      {
        selector: gettingStartedElement,
        content: (
          <>
            <Confetti active={openConfetti} config={config} />
            <h2>💡 And that's how easy leaving feedback is with BugHerd</h2>
            <p>What's next?</p>
            <p>
              Click the <strong>Setup guide</strong> to:
              <ul
                style={{
                  listStyle: 'disc',
                  margin: '1rem',
                  paddingLeft: '12px'
                }}
              >
                {' '}
                <li>Add team members</li>
                <li>Set up integrations, or</li>
                <li>Leave more feedback on your project.</li>
              </ul>
            </p>
          </>
        ),
        action: node => {
          const handleOnClick = () => {
            setIsTourOpen(false);
            node.removeEventListener('click', handleOnClick);
          };
          node.addEventListener('click', handleOnClick);
        },
        active: !!document.querySelector(gettingStartedElement)
      }
    ];
  };

  useEffect(() => {
    if (!isKanbanPage || isMobileView) return;

    const MAX_TRIES = 50;
    let tries = 0;

    const intervalId = setInterval(() => {
      if (tries >= MAX_TRIES) return clearInterval(intervalId);
      if (document.querySelector(taskElement)) {
        const initialActiveSteps = getTourSteps().filter(step => step.active);
        setActiveSteps(initialActiveSteps);
        setIsTourOpen(true);
        clearInterval(intervalId);
      }
      tries++;
    }, 200);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isTourOpen) return;

    const openConfetti = activeSteps.length === tourStep + 1;

    const initialActiveSteps = getTourSteps(openConfetti).filter(
      step => step.active
    );
    setActiveSteps(initialActiveSteps);
  }, [tourStep]);

  return (
    <Tour
      steps={activeSteps}
      isOpen={isTourOpen}
      onRequestClose={() => setIsTourOpen(false)}
      accentColor="#3f71e0"
      closeWithMask={false}
      disableDotsNavigation
      goToStep={tourStep}
      lastStepNextButton={<Button type="primary">Finish</Button>}
      rounded={12}
      scrollDuration={500}
      showNavigation={false}
      showCloseButton={false}
      showButtons={activeSteps[tourStep]?.showButtons || false}
      prevStep={goToPrevTourStep}
      onBeforeClose={handleFinishTour}
      nextButton={<Button type="primary">Next</Button>}
      prevButton={<Button>Back</Button>}
      nextStep={() => {
        if (activeSteps[tourStep]?.selector === taskDetailsElement) {
          closeTask();
          goToNextTourStep();
        } else {
          goToNextTourStep();
        }
      }}
    />
  );
};

export default ProjectTour;
