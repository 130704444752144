import React, { useState, FC, useRef, MutableRefObject } from 'react';

import { UploadFile } from 'antd/lib/upload/interface';

import * as Types from '../types';
import { Task } from '../types/task';

import Preview from '../preview';
import AttachFile from '../attach_file';
import Description from '../description';

import cx from 'classnames';
import styles from './index.module.css';
import { PickerButton } from '../../../SidebarPanel/Detailbar/CommentsAndLog/Comments/CommentStream/Comment/Reactions/PickerButton';
import { handleEmojiSelect } from './handleEmojiSelect';

type Props = {
  setUploadingState: (state: boolean) => void;
  container: HTMLDivElement;
  task: Task;
  accessTo: Types.AccessTo;
  modalContainer: HTMLDivElement;
  modalOuter: HTMLDivElement;
  attachmentsUrl: string;
  removeFile: (file: UploadFile<any>) => void;
  fileList: any[];
  onFileListChange: (fileList: any[]) => void;
  description: string;
  onDescriptionChange: (description: string) => void;
  submitTask: () => any;
  severity: Types.Severity;
  setOverlay: (overlay: Types.Overlay) => void;
  onAnnotationChange: (dataURI: Blob) => void;
  dataURI?: Blob | null;
  isAdminView: boolean;
  subscriptionUrl: string;
  owner: {
    id: number;
    email?: string;
  };
  apiDomain: string;
};

const LeftPanel: FC<Props> = props => {
  const {
    setUploadingState,
    container,
    task,
    accessTo,
    modalContainer,
    modalOuter,
    attachmentsUrl,
    removeFile,
    fileList,
    onFileListChange,
    description,
    onDescriptionChange,
    submitTask,
    severity,
    setOverlay,
    onAnnotationChange,
    dataURI,
    isAdminView,
    subscriptionUrl,
    owner,
    apiDomain
  } = props;

  const showScreenshotPreview = !isAdminView;
  const [emojiPopoverOpen, setEmojiPopoverOpen] = useState<boolean>(false);
  const [caretPosition, setCaretPosition] = useState<number>(0);
  const descriptionRef: MutableRefObject<HTMLTextAreaElement | null> = useRef(
    null
  );

  const onEmojiSelect = (emoji: { native: string }) => {
    handleEmojiSelect({
      emoji,
      description,
      caretPosition,
      onDescriptionChange,
      setCaretPosition,
      descriptionRef
    });
  };

  const closePopoverWhenTypingContinues = () => {
    if (emojiPopoverOpen) setEmojiPopoverOpen(false);
  };

  return (
    <div className={cx(styles.leftPanel)}>
      <div
        className={cx({
          [styles.descriptionOuter]: true,
          [styles.shortDescriptionOuter]: !!task.screenshotPolyfill
        })}
      >
        <Description
          descriptionRef={descriptionRef}
          submitTask={submitTask}
          description={description}
          onDescriptionChange={onDescriptionChange}
          setCaretPosition={setCaretPosition}
          fullHeight={!!task.screenshotPolyfill}
          caretPosition={caretPosition}
          closePopoverWhenTypingContinues={closePopoverWhenTypingContinues}
        />
        <div className={styles.descriptionFooter}>
          <div className={styles.footerRight}>
            <PickerButton
              getContainer={() => container}
              onEmojiSelect={onEmojiSelect}
              tooltipTitle="Add emoji"
              placement="rightTop"
              setPopoverOpen={() => setEmojiPopoverOpen(!emojiPopoverOpen)}
              popoverOpen={emojiPopoverOpen}
              apiDomain={apiDomain}
            />
            <AttachFile
              {...{
                endpoint: attachmentsUrl,
                setUploadingState,
                fileList,
                onFileListChange,
                removeFile,
                modalContainer,
                modalOuter
              }}
            />
          </div>
        </div>
      </div>
      {showScreenshotPreview && task && (
        <Preview
          {...{
            task,
            dataURI,
            container,
            accessTo,
            severity,
            setOverlay,
            owner,
            subscriptionUrl,
            onAnnotationChange
          }}
        />
      )}
    </div>
  );
};

export default LeftPanel;
