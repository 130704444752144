import React, {
  FC,
  useState,
  useRef,
  MutableRefObject,
  useEffect
} from 'react';
import {
  getFileListCopy,
  getFileInfo,
  FileType,
  AttachedFile,
  getAttachedFileInfo
} from '../../../../../../javascript/utils/fileListOperations';
import { Dragger } from '../../../../../../javascript/utils/uploadDragListeners';
import uploadToS3 from '../../../../../../javascript/utils/uploadToS3';
import { Paperclip as Attach, Upload as UploadIcon } from 'lucide-react';
import { Tag, Upload, Alert, message } from 'antd';
import * as translations from './strings';
import { post, deleteItem } from '../../../../../../javascript/utils/fetch';
import { getLangKey } from '../../../../models/Language';
import { Attachment } from '../../../../models/Task';
import Loader from '../../../../components/Loader';

import cx from 'classnames';
import styles from './styles.module.css';
import DetailsLabel from '../DetailsLabel';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

const strings = translations[getLangKey()];

type Props = {
  attachments: Attachment[];
  onUpdateAttachments: (attachments: Attachment[]) => void;
  bugherdUrl: string;
  apiDomain: string;
  projectId: string | number;
  taskId: number;
  isAdminView?: boolean;
  isNewSidebar?: boolean;
  container: HTMLDivElement;
  pastedFile?: File | null;
};

const Attachments: FC<Props> = ({
  attachments,
  onUpdateAttachments,
  bugherdUrl,
  apiDomain,
  projectId,
  taskId,
  isAdminView,
  container,
  isNewSidebar,
  pastedFile
}) => {
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [attachedFiles, setAttachedFiles] = useState<AttachedFile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const isMounted: MutableRefObject<boolean> = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const addTaskId = (attachment: Attachment) => ({
      ...attachment,
      taskId
    });
    if (taskId) {
      const attachedFilesForThisTask: AttachedFile[] = attachedFiles.filter(
        attachedFile => attachedFile.taskId === taskId
      );
      const unattachedFilesForThisTask: Attachment[] = attachments.filter(
        attachment =>
          !attachedFilesForThisTask.find(
            attachedFile => attachedFile.url === attachment.url
          )
      );

      if (attachedFilesForThisTask.length) {
        if (unattachedFilesForThisTask.length) {
          setAttachedFiles(
            attachedFiles.concat(unattachedFilesForThisTask.map(addTaskId))
          );
        }
      } else {
        setAttachedFiles(attachedFiles.concat(attachments.map(addTaskId)));
      }
    }
  }, [taskId]);

  const showAlert = (message: string) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 4000);
  };

  const deleteAttachment = (id?: number) => {
    const attachmentsClone = attachments.slice();
    const attachedFilesClone = getFileListCopy(attachedFiles);
    setAttachedFiles(
      attachedFiles.filter(attachedFile => attachedFile.id !== id)
    );
    onUpdateAttachments(attachments.filter(attachment => attachment.id !== id));
    deleteItem(
      `${apiDomain ||
        bugherdUrl}/projects/${projectId}/tasks/${taskId}/attachments/${id}`
    ).catch(error => {
      console.log(error);
      setAttachedFiles(attachedFilesClone);
      onUpdateAttachments(attachmentsClone);
    });
  };

  const showErrorMessage = (content: string) =>
    isAdminView ? message.error(content, 4) : showAlert(content);

  const endpoint: string = `${bugherdUrl}/projects/${projectId}/tasks/${taskId}/attachments/new`;
  const saveEndpoint: string = `${bugherdUrl}/sidebar/projects/${projectId}/tasks/${taskId}/set_attachment`;

  const remove = (uid: string) => {
    const updatedList = fileList.filter((_file: FileType) => _file.uid !== uid);
    setFileList(updatedList);
    setAttachedFiles(
      attachedFiles.filter(
        attachedFile => !attachedFile.uid || uid !== attachedFile.uid
      )
    );
  };

  const updateFileInList = (_uid: string, data: any) => {
    setFileList(_fileList => {
      let listClone: FileType[] = getFileListCopy(_fileList);
      const file: FileType | undefined = listClone.find(
        ({ uid }: FileType) => uid === _uid
      );
      if (file) {
        Object.assign(file, data);
      }
      return listClone;
    });
  };

  const updateAttachedFileInList = (_uid: string, data: any) => {
    setAttachedFiles(_attachedFiles => {
      let attachedFilesClone: AttachedFile[] = getFileListCopy(_attachedFiles);
      const attachedFile: AttachedFile | undefined = attachedFilesClone.find(
        ({ uid }: AttachedFile) => uid && uid === _uid
      );
      if (attachedFile) {
        Object.assign(attachedFile, data);
      }
      return attachedFilesClone;
    });
  };

  const updateFileAndAttachedFile = (_uid: string, data: any) => {
    updateFileInList(_uid, data);
    updateAttachedFileInList(_uid, data);
  };

  const onProgress = (
    { loaded, total, lengthComputable }: ProgressEvent,
    file: FileType
  ) => {
    if (isMounted && lengthComputable) {
      let percent: number = Math.round((loaded / total) * 100);
      updateFileAndAttachedFile(file.uid, {
        percent,
        status: percent !== 100 ? 'uploading' : 'done'
      });
    }
  };

  const onError = (event: any, file: FileType) => {
    showErrorMessage(strings.wentWrong);
    remove(file.uid);
  };

  const onComplete = async (_file: FileType, S3URL: string, key: string) => {
    try {
      const response = await post(saveEndpoint, {
        key,
        filename: _file.name,
        size_in_kb: _file.size ? Math.round(_file.size / 1000) : 0
      });
      const data: any = {
        url: S3URL + key,
        createdAt: new Date().toISOString(),
        id: response.id,
        status: 'done',
        percent: 100
      };
      updateFileAndAttachedFile(_file.uid, data);
      if (isNewSidebar) {
        const attachmentsClone = attachments.slice();
        attachmentsClone.unshift(
          Object.assign(response, { createdAt: data.createdAt })
        );
        onUpdateAttachments(attachmentsClone);
      }
    } catch (err) {
      showErrorMessage(strings.wentWrong);
      console.log(err);
    }
  };

  const handleOnChange = (
    event: UploadChangeParam<UploadFile<any>> | null,
    pastedFile: File
  ) => {
    const file =
      (event && event.file && event.file.originFileObj) ?? event ?? pastedFile;
    if (file) {
      if (file.size > 20485760) {
        return showErrorMessage(strings.tooLarge(file.name));
      }
      const _file: FileType = getFileInfo(file, taskId);
      const attachedFile: AttachedFile = getAttachedFileInfo(_file, taskId);
      setAttachedFiles(files => {
        const _attachedFiles = getFileListCopy(files);
        _attachedFiles.unshift(attachedFile);
        return _attachedFiles;
      });
      setFileList(files => {
        const _fileList = getFileListCopy(files);
        _fileList.unshift(_file);
        return _fileList;
      });
    }
  };

  useEffect(() => {
    if (pastedFile) {
      handleOnChange(null, pastedFile);
    }
  }, [pastedFile]);

  const overlay = (
    <div className={styles.overlayBox}>
      <UploadIcon className={styles.overlayIcon} />
      <p className={styles.dragText}>{strings.dragText}</p>
    </div>
  );

  useEffect(() => {
    const listClone = fileList.slice();
    const uninitializedFiles = fileList.filter(
      ({ initialized }: FileType) => initialized === false
    );
    uninitializedFiles.forEach((file: FileType) => {
      const fileIndex: number = listClone.findIndex(
        ({ uid }: FileType) => uid === file.uid
      );
      listClone[fileIndex].initialized = true;
      const uploadParameters = {
        file,
        remove,
        endpoint,
        additionalQueryParams: { filename: file.name },
        feedback: showErrorMessage,
        onStart: () => {},
        onProgress,
        onError,
        onComplete
      };
      uploadToS3(uploadParameters);
    });
    if (uninitializedFiles.length) {
      setFileList(listClone);
    }
  }, [fileList]);

  const sortAttachments = (a: AttachedFile, b: AttachedFile) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  return (
    <Dragger
      container={container}
      prevent={window}
      handleOnChange={handleOnChange}
    >
      {(draggingFile: boolean) => (
        <div className={styles.attachmentsOuter}>
          <div className={styles.attachmentsInner}>
            <DetailsLabel label={strings.attachments} />
            <div className={styles.innerContainer}>
              {!draggingFile && (
                <div className={styles.attachmentsBox}>
                  {attachedFiles
                    .sort(sortAttachments)
                    .filter(attachedFile => attachedFile.taskId === taskId)
                    .map(
                      ({ status, name, uid, url, percent, id }: AttachedFile) =>
                        uid && status === 'uploading' ? (
                          <Tag
                            className={cx(styles.tag, styles.uploading)}
                            closable
                            onClose={() => remove(uid)}
                            key={url + uid}
                          >
                            <div className={styles.tagContent}>
                              <Loader
                                className={styles.loaderIcon}
                                useDarkStyles
                              />
                              <span className={styles.filename} title={name}>
                                {name}
                              </span>
                            </div>
                            <div className={styles.progressOuter}>
                              <div
                                className={cx(styles.progressInner, {
                                  [styles.almost]:
                                    percent !== undefined && percent >= 85
                                })}
                                style={{ width: `${percent}%` }}
                              />
                            </div>
                          </Tag>
                        ) : (
                          <Tag
                            className={styles.tag}
                            closable
                            onClose={() => deleteAttachment(id)}
                            key={url + name}
                          >
                            <div className={styles.tagContent}>
                              <Attach className={styles.attachIcon} />
                              <a
                                href={url}
                                rel="noreferrer"
                                target="_blank"
                                className={styles.previewLink}
                              >
                                <span className={styles.filename} title={name}>
                                  {name}
                                </span>
                              </a>
                            </div>
                          </Tag>
                        )
                    )}
                </div>
              )}
              <Upload
                name="file"
                fileList={fileList}
                onChange={handleOnChange}
                showUploadList={false}
                customRequest={() => {}}
                multiple
                className={styles.upload}
              >
                {!draggingFile && (
                  <Tag className={styles.uploadButton}>
                    <UploadIcon className={styles.uploadIcon} />
                    {strings.attachFile}
                  </Tag>
                )}
              </Upload>
              {draggingFile && overlay}
            </div>
          </div>
          {errorMessage && !draggingFile && (
            <Alert
              className={styles.alert}
              type="error"
              message={errorMessage}
              showIcon
              closable
            />
          )}
        </div>
      )}
    </Dragger>
  );
};

export default Attachments;
