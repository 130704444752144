import { useState, useEffect } from 'react';
import { deleteItem, post } from '../utils/fetch';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';
import {
  CommentParams,
  UpdateComment,
  updateComment
} from '../../../clients/shared/updateComment';

export type Comment = {
  id: number;
  text: string;
  reactions: string;
};

type Props = {
  taskId: number;
  projectId: number;
  bugherdUrl: string;
  comments: Comment[];
  currentUser: any;
};

const useComments = ({
  taskId,
  projectId,
  bugherdUrl,
  comments,
  currentUser
}: Props): {
  createComment: (commentBody: { isPrivate: boolean; text: string }) => void;
  deleteComment: (commentId: number) => void;
  updateComment: UpdateComment;
  comments: Comment[];
} => {
  const [_comments, setComments] = useState<Comment[]>([]);

  const endpoint = (commentId?: number) =>
    `${bugherdUrl}/projects/${projectId}/tasks/${taskId}/comments${
      commentId ? '/' + commentId : ''
    }`;

  useEffect(() => {
    setComments(camelCaseKeys(comments, { deep: true }));
  }, [taskId, comments]);

  const deleteComment = (commentId: number) => {
    const commentsClone = _comments.slice();
    const commentsToSet = commentsClone.filter(
      ({ id }: Comment) => commentId !== id
    );
    setComments(commentsToSet);
    deleteItem(endpoint(commentId)).catch(error => {
      console.error(error);
      setComments(commentsClone);
    });
  };

  type CommentBody = {
    isPrivate: boolean;
    text: string;
  };

  const newComment = ({ isPrivate, text }: CommentBody) => ({
    id: -1,
    isPrivate,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    text,
    userId: currentUser.id,
    user: currentUser,
    readByUsers: [currentUser.id],
    reactions: '{}'
  });

  const createComment = (commentBody: CommentBody) => {
    const commentsClone = _comments.slice();
    const commentsToEdit = commentsClone.slice();
    commentsToEdit.push(newComment(commentBody));
    setComments(commentsToEdit);

    post(endpoint(), snakeCaseKeys(commentBody, { deep: true }))
      .then(response => {
        const commentsCopy = _comments.filter(({ id }: Comment) => id !== -1);
        const incomingComment = camelCaseKeys(response, { deep: true });
        commentsCopy.push(incomingComment);
        setComments(commentsCopy);
      })
      .catch(error => {
        console.error(error);
        setComments(commentsClone);
      });
  };

  return {
    createComment,
    deleteComment,
    updateComment: ({ commentId, commentEvent }: CommentParams) =>
      updateComment({
        commentId,
        setComments,
        comments: _comments,
        endpoint: endpoint(commentId),
        commentEvent
      }),
    comments: _comments
  };
};

export default useComments;
