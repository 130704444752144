export const addCelloUccToFields = async (values: {}) => {
  const newValues = { ...values };
  if ('CelloAttribution' in window) {
    // eslint-disable-next-line new-cap
    try {
      const ucc = await window.CelloAttribution('getUcc');

      newValues['landing_cello_ucc'] = ucc;
    } catch (err) {
      console.error(
        `Error getting UCC: ${err}; could not attribute Cello user`
      );
    }
  }

  return newValues;
};
