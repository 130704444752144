import React, { FC, useEffect, useState } from 'react';
import { Dropdown, Menu, Button, message } from 'antd';
import {
  ChevronUp as Up,
  ChevronDown as Down,
  Upload,
  Trash as Delete,
  ArrowDown,
  Check
} from 'lucide-react';
import * as translations from './strings';
import { getLangKey } from '../../../../../models/Language';
import sortUserFriendly, {
  SortableProject
} from '../../../../../../../javascript/models/project/sortUserFriendly';

import styles from './styles.module.css';
import cx from 'classnames';
import Loader from '../../../../../components/Loader';

type Props = {
  isAdminView?: boolean;
  container: HTMLDivElement;
  projectId: number;
  taskId: number;
  taskDelete: boolean;
  taskMoveToProject: boolean;
  projects: SortableProject[];
  projectsLoading: boolean;
  shareUrl: string;
  isSmallDetailsView?: boolean;
  moveToProject: (projectId: number) => void;
  deleteTask: (
    onError?: (error: Error) => void,
    onSuccess?: () => void
  ) => void;
  isNewSidebar?: boolean;
};

const strings = translations[getLangKey()];

const More: FC<Props> = ({
  isAdminView,
  container,
  projectId,
  taskId,
  taskDelete,
  taskMoveToProject,
  projects,
  projectsLoading,
  shareUrl,
  isSmallDetailsView,
  moveToProject,
  deleteTask,
  isNewSidebar
}) => {
  const [moreVisible, setMoreVisible] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [recentProjects, setRecentProjects] = useState<SortableProject[]>([]);
  const [sortedProjects, setSortedProjects] = useState<SortableProject[]>([]);
  const [showAllProjects, setShowAllOtherProjects] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    if (!projects.length) return;
    const groupedProjects = sortUserFriendly({
      projects: projects.map((project: SortableProject) => ({
        ...project,
        updatedAt: project.updatedAt
      }))
    });

    setRecentProjects(groupedProjects[0]);
    setSortedProjects(
      groupedProjects[1].filter(
        (groupedProject: SortableProject) => groupedProject.id !== projectId
      )
    );
  }, [projects]);

  const copyShareUrl = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      message.success(strings.shareCopyMessage);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  const handleMoveToProject = (projectId: number) => {
    try {
      moveToProject(projectId);
    } catch (error) {
      message.error(strings.taskNotMoved);
    }
  };

  const handleDeleteTask = () => {
    let _deleteTask = deleteTask;
    if (isNewSidebar)
      _deleteTask = deleteTask.bind(
        null,
        error => {
          console.error(error);
          message.error(strings.taskNotDeleted);
          setIsDeleting(false);
        },
        () => {
          message.success(strings.taskDeleted);
          setIsDeleting(false);
        }
      );
    setConfirmDelete(false);
    setIsDeleting(true);
    _deleteTask();
  };

  const getProjectMenuItems = (projects: SortableProject[]) =>
    projects
      .filter((project: SortableProject) => projectId !== project.id)
      .map((project: SortableProject) => (
        <Menu.Item
          onClick={() => handleMoveToProject(project.id)}
          key={project.id}
          className={styles.moreMenuItem}
          title={project.name}
        >
          <span className={styles.projectName}>{project.name}</span>
        </Menu.Item>
      ));

  return (
    <Dropdown
      // @ts-ignore
      type="secondary"
      getPopupContainer={() => container}
      trigger={['click']}
      className={styles.antdDropdownOverride}
      placement="bottomRight"
      overlay={
        <Menu
          id="moreMenu"
          className={cx(styles.moreMenu, {
            [styles.moreMenuAdmin]: isAdminView,
            [styles.moreMenuSidebar]: !isAdminView,
            [styles.moveMenu]: true
          })}
          onBlur={() => setMoreVisible(false)}
          onScroll={event => event.stopPropagation()}
        >
          <Menu.Item
            key="shareUrl"
            className={styles.shareUrl}
            onClick={copyShareUrl}
          >
            {isCopied ? (
              <>
                <Check className={styles.moreMenuItemIcon} />
                {strings.copied}
              </>
            ) : (
              <>
                <Upload className={styles.moreMenuItemIcon} />
                {strings.copyShareUrl}
              </>
            )}
          </Menu.Item>
          {taskDelete && (
            <Menu.Item
              key="delete"
              className={cx(styles.delete, {
                [styles.confirmDelete]: confirmDelete
              })}
              onClick={
                confirmDelete ? handleDeleteTask : () => setConfirmDelete(true)
              }
            >
              {isDeleting ? (
                <>
                  <Loader className={styles.deleteLoader} useDarkStyles />
                  {strings.deletingTask}
                </>
              ) : (
                <>
                  <Delete className={styles.moreMenuItemIcon} />
                  {confirmDelete ? strings.confirmDelete : strings.deleteTask}
                </>
              )}
            </Menu.Item>
          )}
          {taskMoveToProject && (
            <>
              <Menu.Divider />
              <Menu.ItemGroup key="g1" title={strings.moveToProject}>
                {getProjectMenuItems(recentProjects)}
                {recentProjects.length > 0 && <Menu.Divider />}
                {showAllProjects || recentProjects.length === 0 ? (
                  getProjectMenuItems(
                    sortedProjects.filter(
                      (project: SortableProject) =>
                        !recentProjects.includes(project)
                    )
                  )
                ) : (
                  <>
                    <Menu.Item
                      key="showAllOtherProjects"
                      className={styles.showAllOtherProjects}
                      onClick={() => setShowAllOtherProjects(true)}
                    >
                      <ArrowDown className={styles.moreMenuItemIcon} />
                      {strings.showAllOtherProjects}
                    </Menu.Item>
                  </>
                )}
              </Menu.ItemGroup>
            </>
          )}
        </Menu>
      }
      open={moreVisible}
      onOpenChange={setMoreVisible}
    >
      <Button className={styles.moreActionsButton}>
        <span className={styles.moreActionsInner}>{strings.more}</span>
        {moreVisible ? (
          <Up className={styles.menuToggleIcon} />
        ) : (
          <Down className={styles.menuToggleIcon} />
        )}
      </Button>
    </Dropdown>
  );
};

export default More;
